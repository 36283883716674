<template>
  <BaseForm title="CPO Eintragen" @onConfirmClicked="onConfirmClicked">
    <form>
      <v-select
        v-model="oneStepAccountsId"
        label="Account"
        item-value="id"
        :item-text="accountDisplayText"
        :items="accountList"
        required
      >
      </v-select>
      <v-select
        v-model="oneStepArticleMappingId"
        label="Artikel"
        item-value="id"
        :item-text="articleDisplayText"
        :items="accountArtikelList"
      ></v-select>
      <v-text-field
        prefix="€"
        v-model="cpo"
        label="CPO"
        required
        type="number"
      ></v-text-field>
      <v-menu
        ref="menu2"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="validFrom"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="validFrom"
            label="Gültig ab"
            prepend-icon="mdi-calendar"
            required
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="validFrom" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="$refs.menu2.save(validFrom)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </form>
  <v-container fluid>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="createdAt"
            label="Erstellt am"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="createdBy"
            label="Erstellt von"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </BaseForm>
</template>

<script>
import { API } from "aws-amplify";
import {
  listOneStepAccounts,
  listOneStepArticleMappings,
} from "@/graphql/queries";
import { createOneStepCpo } from "@/graphql/mutations";
import BaseForm from "@/components/baseCrud/BaseForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "app",

  components: {
    BaseForm,
  },
  data() {
    return {
      mandatorCode: "VNR",
      articleCode: null,
      isEmf: false,
      validFrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mandatorList: ["VNR", "FKD"],
      menu: false,
      createdBy: [],
      accountList: [],
      accountId: null,
      accountArtikelList: [],
      oneStepArticleMappingId: null,
      oneStepAccountsId: null,
      cpo: 0,
    };
  },
  async created() {
    this.getAccountList();
    this.getArticleList();
    this.createdBy = this.kurzel;
  },
  methods: {
    accountDisplayText(item) {
      return `${item.networkCode} - ${item.accountId}`;
    },
    articleDisplayText(item) {
      return `${item.mandatorCode} - ${item.articleCode} - ${
        item.isEmf ? "EMF" : "ABO"
      }`;
    },
    async createMethod() {
      const {
        oneStepArticleMappingId,
        oneStepAccountsId,
        cpo,
        validFrom,
        createdBy,
      } = this;
      const input = {
        oneStepArticleMappingId,
        oneStepAccountsId,
        cpo,
        validFrom,
        createdBy,
      };
      await API.graphql({
        query: createOneStepCpo,
        variables: { input: input },
      });
      this.$router.push({ name: "PpcOneCpoList" });
      this.$emit("refresh");
    },
    onConfirmClicked() {
      this.createMethod();
    },
    async getAccountList() {
      this.loading = true;
      const response = await API.graphql({
        query: listOneStepAccounts,
      });
      this.accountList = response.data.listOneStepAccounts.items;
      this.loading = false;
    },
    async getArticleList() {
      this.loading = true;
      const response = await API.graphql({
        query: listOneStepArticleMappings,
        variables: {
          limit: 10000,
        },
      });
      this.accountArtikelList = response.data.listOneStepArticleMappings.items;
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters(["user", "kurzel"]),
  },
};
</script>