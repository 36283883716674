<template>
  <v-container fluid>
    <BaseTable
      :items="convertedItems"
      :headers="headers"
      title="CPO"
      :editLink="'PpcOneCpoCreateUpdateForm'"
      :createLink="'PpcOneCpoCreateUpdateForm'"
      :deleteMutation="'deleteOneStepCpo'"
      @refresh="updateList"
      :loading="loading"
    />
  </v-container>
</template>

<script>
import { API } from "aws-amplify";
import { listOneStepCpos } from "@/graphql/queries";
import BaseTable from '@/components/BaseTable.vue';

export default {
  name: "Anfo",
  components: {
    BaseTable,
  },
  async created() {
    this.updateList();
  },
  methods: {
    updateList() {
      this.getList();
    },
    async getList() {
      this.loading = true;
      const response = await API.graphql({ query: listOneStepCpos});
      this.items = response.data.listOneStepCpos.items;
      this.loading = false;
    },
    articleDisplayText(item) {
      return `${item.mandatorCode} - ${item.articleCode} - ${
        item.isEmf ? "EMF" : "ABO"
      }`;
    },
  },
  computed: {
    convertedItems() {
      return this.items
        .map(v => ({...v, accountKey: `${v.OneStepAccounts.networkCode} - ${v.OneStepAccounts.accountId}`}))
        .map(v => ({...v, articleKey: this.articleDisplayText(v.OneStepArticleMapping)}))
    },
  },
  data() {
    return {
      headers: [
        { text: "Network", value: "accountKey" },
        { text: "Artikel", value: "articleKey" },
        { text: "CPO", value: "cpo" },
        { text: "Gültig ab", value: "validFrom" },
      ],
      items: [],
      loading: true,
    };
  },
};
</script>
