<template>
  <v-container fluid>
    <BaseTable
      :items="items"
      :headers="headers"
      title="Artikel"
      :editLink="'PpcOneArticleCreateUpdateForm'"
      :createLink="'PpcOneArticleCreateUpdateForm'"
      :deleteMutation="'deleteOneStepArticleMapping'"
      @refresh="updateList"
      :loading="loading"
    />
  </v-container>
</template>

<script>
import { API } from "aws-amplify";
import { listOneStepArticleMappings } from "@/graphql/queries";
import BaseTable from "@/components/BaseTable.vue";

export default {
  name: "Anfo",
  components: {
    BaseTable,
  },
  async created() {
    this.updateList();
  },
  methods: {
    updateList() {
      this.getList();
    },
    async getList() {
      this.loading = true;
      const response = await API.graphql({ query: listOneStepArticleMappings });
      this.items = response.data.listOneStepArticleMappings.items;
      this.loading = false;
    },
  },
  data() {
    return {
      headers: [
        { text: "VNR/FKD", value: "mandatorCode" },
        { text: "Artikel", value: "articleCode" },
        { text: "EMF?", value: "isEmf" },
        // { text: "Intern / Extern", value: "internExtern" },
      ],
      items: [],
      loading: true
    };
  },
};
</script>
