<template>
  <BaseForm
    title="Vertrag Bezahlt Quote Überschreibung"
    @onConfirmClicked="onConfirmClicked"
  >
    <form>
      <v-container fluid>
        <v-row align="center" justify="center">
          <v-col cols="4">
            <v-autocomplete
              v-model="data.articleID"
              label="Artikel"
              item-value="id"
              item-text="code"
              :items="articleList"
              :error-messages="articleErrors"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="data.marketingTypeID"
              label="Werbeweg"
              item-value="id"
              item-text="code"
              :items="marketingTypeList"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="data.conditionID"
              label="Vert. Kondition"
              item-value="id"
              item-text="name"
              :items="contractConditionGroupList"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="data.minMax"
              label="Grenztyp"
              :items="minMaxList"
              required
              item-value="code"
              item-text="name"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="data.contractPayedRatioTheshold"
              :error-messages="contractPayedRatioThesholdErrors"
              :counter="10"
              label="Quotengrenze in %"
              required
              @input="$v.contractPayedRatioTheshold.$touch()"
              @blur="$v.contractPayedRatioTheshold.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="6">
            <v-text-field
              v-model="data.reason"
              :error-messages="reasonErrors"
              label="Grund (Freitext)"
              required
              @input="$v.reason.$touch()"
              @blur="$v.reason.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" class="ma-3">
          <v-col cols="6">
          <BaseField
            :field="fieldDef[0]"
            v-model="data[fieldDef[0].techName]"
          />
          </v-col>
          <v-col cols="6">
          <BaseField
            :field="fieldDef[1]"
            v-model="data[fieldDef[1].techName]"
          />
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="3">
            <v-text-field
              v-model="data.createdBy"
              :error-messages="createdByErrors"
              label="Erstellt von"
              required
              disabled
              @input="$v.createdBy.$touch()"
              @blur="$v.createdBy.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </form>
  </BaseForm>
</template>

<script>
import { API } from "aws-amplify";
import { createOverwriteContractPayedRatio } from "@/graphql/mutations";
import {
  listArticles,
  listMarketingTypes,
  listConditions,
} from "@/graphql/queries";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import BaseForm from "@/components/baseCrud/BaseForm.vue";
import BaseField from "@/components/baseCrud/BaseField.vue";

export default {
  name: "app",
  mixins: [validationMixin],
  components: {
    BaseForm,
    BaseField,
  },
  data() {
    return {
      data: {
        articleID: null,
        marketingTypeID: null,
        conditionID: null,
        minMax: "MIN",
        contractPayedRatioTheshold: null,
        reason: "",
        type: "MONTH_PRICE",
        createdBy: "",
        isApproved: false,
        approvedBy: "",
        validFrom: null,
        validTill: null,
      },
      fieldDef: [
        {
          name: "Gültig von",
          techName: "validFrom",
          mandatory: true,
          fieldType: "date",
          default: new Date(),
        },
        {
          name: "Gültig bis",
          techName: "validTill",
          mandatory: true,
          fieldType: "date",
          default: new Date(),
        },
      ],
      minMaxList: [
        {
          id: 1,
          code: "MIN",
          name: "Untergrenze",
        },
        {
          id: 2,
          code: "MAX",
          name: "Obergrenze",
        },
      ],
      priceTypeList: [
        {
          id: 1,
          code: "FIX_PRICE",
          name: "Startpreis",
        },
        {
          id: 2,
          code: "MONTH_PRICE",
          name: "Monatspreis",
        },
      ],
      articleList: [],
      marketingTypeList: [],
      contractConditionGroupList: [],
    };
  },
  validations: {
    valid: { required },
    article: { required },
    minMax: { required },
    contractPayedRatioTheshold: { required },
    reason: { required },
    createdBy: { required },
    isApproved: { required },
    approvedBy: { required },
  },
  async created() {
    this.setCreatedBy();
    this.getContractConditionGroupList();
    this.getArticleList();
    this.getMarketingTypeList();
    this.data.createdBy = this.kurzel;
  },
  methods: {
    async createMethod() {
      const input = {
        contractPayedRatioTheshold: this.data.contractPayedRatioTheshold,
        minMax: this.data.minMax,
        articleID: this.data.articleID,
        marketingTypeID: this.data.marketingTypeID,
        conditionID: this.data.conditionID,
        createdBy: this.data.createdBy,
        reason: this.data.reason,
        validFrom: this.convertDate(this.data.validFrom),
        validTill: this.convertDate(this.data.validTill),
      };
      await API.graphql({
        query: createOverwriteContractPayedRatio,
        variables: { input: input },
      });
      this.$router.push({ name: "NgwOverwriteContractPayedRatioList" });
      this.$emit("refresh");
    },
    onConfirmClicked() {
      this.createMethod();
    },
    convertDate(date) {
      return this.$moment(date, 'YYYY-MM-DD','de').format('YYYY-MM-DD')
    },
    async getArticleList() {
      this.loading = true;
      const response = await API.graphql({
        query: listArticles,
        variables: {
          limit: 10000,
        },
      });
      this.articleList = response.data.listArticles.items.sort((a, b) => {
          if (a.code && b.code) {
            var textA = a.code.toUpperCase();
            var textB = b.code.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          }
          return 0;
      });
      this.loading = false;
    },
    async getMarketingTypeList() {
      this.loading = true;
      const response = await API.graphql({
        query: listMarketingTypes,
      });
      this.marketingTypeList = response.data.listMarketingTypes.items;
      this.loading = false;
    },
    async getContractConditionGroupList() {
      this.loading = true;
      const response = await API.graphql({
        query: listConditions,
      });
      this.contractConditionGroupList = response.data.listConditions.items;
      this.loading = false;
    },
    setCreatedBy() {
      if (this.user) {
        this.data.createdBy = this.user.email;
      }
    },
    onCancelClicked() {
      this.$router.go(-1);
    },
    articleDisplayText(item) {
      return `${item.mandatorCode} - ${item.articleCode}`;
    },
  },
  computed: {
    validFrom() {
      return this.valid[0];
    },
    validTill() {
      return this.valid[1];
    },
    articleErrors() {
      const errors = [];
      if (!this.$v.article.$dirty) return errors;
      !this.$v.article.required && errors.push("Is required.");
      return errors;
    },
    minMaxErrors() {
      const errors = [];
      if (!this.$v.minMax.$dirty) return errors;
      !this.$v.minMax.required && errors.push("Is required.");
      return errors;
    },
    contractPayedRatioThesholdErrors() {
      const errors = [];
      if (!this.$v.contractPayedRatioTheshold.$dirty) return errors;
      !this.$v.contractPayedRatioTheshold.required &&
        errors.push("Is required.");
      return errors;
    },
    reasonErrors() {
      const errors = [];
      if (!this.$v.reason.$dirty) return errors;
      !this.$v.reason.required && errors.push("Is required.");
      return errors;
    },
    validErrors() {
      const errors = [];
      if (!this.$v.valid.$dirty) return errors;
      !this.$v.valid.required && errors.push("Is required.");
      return errors;
    },
    createdByErrors() {
      const errors = [];
      if (!this.$v.createdBy.$dirty) return errors;
      !this.$v.createdBy.required && errors.push("Is required.");
      return errors;
    },
    isApprovedErrors() {
      const errors = [];
      if (!this.$v.isApproved.$dirty) return errors;
      !this.$v.isApproved.required && errors.push("Is required.");
      return errors;
    },
    approvedByErrors() {
      const errors = [];
      if (!this.$v.approvedBy.$dirty) return errors;
      !this.$v.approvedBy.required && errors.push("Is required.");
      return errors;
    },
    ...mapGetters(["user", "kurzel"]),
  },
};
</script>