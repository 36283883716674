<template>
  <DefaultUpdateForm
    :config="config"
    :tableConfig="tableConfig"
  />
</template>

<script>
import DefaultUpdateForm from "@/components/baseCrud/DefaultUpdateForm.vue";

import configFile from "./config.json";
import basicConfigFile from "./basicConfig.json";

export default {
  name: "UpdateMarketingTypeGroup",

  components: {
    DefaultUpdateForm,
  },
  data() {
    return {
      tableConfig: basicConfigFile,
      config: configFile,
    };
  },
  computed: {
  }
};
</script>