<template>
  <v-container fluid>
    <DefaultTable
      :tableConfig="tableConfig"
      :config="config"
    />
  </v-container>
</template>

<script>
import DefaultTable from "@/components/baseCrud/DefaultTable.vue";
import configFile from './config.json'
import basicConfigFile from "./basicConfig.json";

export default {
  name: "ArtikelTypeList",
  components: {
    DefaultTable,
  },
  data() {
    return {
      tableConfig: basicConfigFile,
      config: configFile,
    };
  },
};
</script>
