<template>
  <v-col cols="12">
    <v-text-field
      v-if="field.fieldType === 'textfield'"
      :label="field.name"
      :value="value"
      @input="onInputChanged"
    >
    </v-text-field>
    <v-currency-field
      v-if="field.fieldType === 'currency'"
      :label="field.name"
      :value="value"
      @input="onInputChanged"
      suffix="€"
      required
    >
    </v-currency-field>
    <v-autocomplete
      v-if="field.fieldType === 'refDropdown'"
      :label="field.name"
      :value="value"
      :items="getRefList"
      required
      @input="onInputChanged"
      item-value="id"
      :item-text="getItemText"
    >
    </v-autocomplete>
    <v-select
      v-if="field.fieldType === 'localRefDropdown'"
      :label="field.name"
      :value="value"
      :items="field.referenceTable"
      required
      @input="onInputChanged"
      item-value="id"
      :item-text="field.referenceDisplay"
    >
    </v-select>
    <v-switch
      v-if="field.fieldType === 'checkbox'"
      :label="field.name"
      :input-value="value"
      @change="onInputChanged"
    >
    </v-switch>
    <v-container v-if="field.fieldType === 'date'">
      <v-text-field
        :value="value"
        v-mask="'####-##-##'"
        placeholder="YYYY-MM-DD"
        @input="onDateInputChanged"
        :label="`${field.name}`"
        hint="YYYY-MM-DD"
      ></v-text-field>
    </v-container>
    <v-container v-if="field.fieldType === 'file'">
      <v-row>
        <v-col cols="6">
          <v-text-field
            :value="value"
            v-model="value"
            label="Datei"
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <!-- 1. Create the button that will be clicked to select a file -->
          <v-btn small fab dark color="green" @click="handleFileImport">
            <v-icon> mdi-cloud-upload </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="1">
          <v-btn
            small
            fab
            dark
            color="darkgrey"
            :disabled="!value"
            @click="showPdf"
          >
            <v-icon> mdi-eye </v-icon>
          </v-btn>
        </v-col>
        <!-- Create a File Input that will be hidden but triggered with JavaScript -->
        <v-col>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            @change="onFileInputChanged"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-col>
</template>

<script>
import { API, Storage } from "aws-amplify";
import * as queries from "@/graphql/queries";
import moment from "moment"; // eslint-disable-line
import { uuid } from "vue-uuid";

export default {
  props: {
    field: {
      type: Object,
      default: null,
    },
    value: {
      default: null,
    },
  },
  data() {
    return {
      itemsPerPage: 1000,
      getRefList: [],
      isSelecting: false,
      selectedFile: null,
    };
  },
  methods: {
    async getData(componentName) {
      let queryName = [];
      if (componentName.endsWith("ss")) {
        queryName = `list${componentName}es`;
      } else {
        queryName = `list${componentName}s`;
      }
      if (componentName.endsWith("Data")) {
        queryName = `list${componentName}`;
      }
      if (componentName.endsWith("y")) {
        queryName = `list${componentName.slice(0, -1)}ies`;
      }
      this.loading = true;
      return this.getQuery(queryName).then((response) => {
        const sortingKey = this.field.referenceDisplay || 'cd'
        return response.data[queryName].items
          .filter((item) => item["_deleted"] !== true)
          .sort((a, b) => {
            if (a[sortingKey] && b[sortingKey]) {
              var textA = a[sortingKey].toUpperCase();
              var textB = b[sortingKey].toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            }
            return 0;
          });
      });
    },
    async getQuery(queryName) {
      return await API.graphql({
        query: queries[queryName],
        variables: {
          limit: this.itemsPerPage,
        },
      });
    },
    onInputChanged(value) {
      this.$emit("input", value);
    },
    onMutliInputChanged(value) {
      this.onInputChanged(value);
    },
    onDateInputChanged(value) {
      const newDate = this.$moment(value, 'YYYY-MM-DD', "de");
      if (newDate.isValid() || !this.field.mandatory) {
        this.onInputChanged(value);
      }
      this.$forceUpdate();
    },
    async handleChange(file) {
      return await Storage.put(`${uuid.v1()}`, file, {
        contentType: "application/pdf",
      });
    },
    async onFileInputChanged(e) {
      const file = e.target.files[0];
      this.handleChange(file).then((response) => {
        this.onInputChanged(response.key);
      });
    },
    handleFileImport() {
      this.isSelecting = true;

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },
    async showPdf() {
      const signedURL = await Storage.get(this.value); // get key from Storage.list
      window.open(signedURL);
    },
    getItemText(item) {
      let template = "";
      this.field.referenceListDisplay.forEach((field, i) => {
        if (i === 0) {
          template = template + item[field];
        } else {
          template = template + " - " + item[field];
        }
      });
      return template;
    },
  },
  created() {
    if (this.field.fieldType === "refDropdown") {
      this.getData(this.field.referenceTable).then((data) => {
        this.getRefList = data;
        if (this.field.defaultValue) {
          const ref = this.field.defaultValue;
          const refList = data.filter(value => value[ref['key']] == ref['value'])
          if (refList && refList.length) {
            this.$emit('setDefaultValue', refList[0].id, this.field.techName)
          }
        }
      })
    }
  },
};
</script>

<style scoped>
</style>
