<template>
  <DefaultCreateForm
    :config="config"
    :tableConfig="tableConfig"
  />
</template>

<script>
import DefaultCreateForm from "@/components/baseCrud/DefaultCreateForm.vue";

import configFile from "./config.json";
import basicConfigFile from "./basicConfig.json";

export default {
  name: "CreateCondition",

  components: {
    DefaultCreateForm,
  },
  data() {
    return {
      tableConfig: basicConfigFile,
      config: configFile,
    };
  },
};
</script>