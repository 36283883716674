<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="3">
          <v-text-field
            v-model="user.given_name"
            label="Vorname"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="user.family_name"
            label="Nachname"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="user.email"
            label="E-Mail"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="user['custom:group']"
            label="custom Group Object"
            readonly
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="jwtContent['cognito:groups']"
            label="Cognito Gruppen"
            readonly
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-select
          v-model="currentlanguage"
          :items="languages"
          :label="t('languageButtonText')"
          item-value="code"
          item-text="name"
        ></v-select>
      </v-row>
      <v-btn @click="onLanguageChangeClicked"> {{ t('selectLanguage') }} </v-btn>
    </v-container>
  </v-form>
</template>

<script>
import { I18n } from "aws-amplify";
import { mapGetters } from "vuex";
import { commonMixin } from '@/mixins/commonMixin';

export default {
  mixins: [commonMixin],
  data: () => ({
    currentlanguage: 'de',
    languages: [
      {
        code: "de",
        name: "Deutsch",
      },
      {
        code: "en",
        name: "English",
      },
    ],
  }),
  created() {
    this.currentlanguage = this.language;
    this.setTranslations({
        'de': {
            'languageButtonText': "Sprache bestätigen",
            'selectLanguage': "Sprache auswählen"
        },
        'en': {
            'languageButtonText': "Use language",
            'selectLanguage': "Pick your Language"
        }
    })
  },
  watch: {
    language(value) {
      this.currentlanguage = value;
    },
  },
  computed: {
    ...mapGetters(["signedIn", "user", 'language', 'jwtContent']),
  },
  methods: {
    onLanguageChangeClicked() {
      I18n.setLanguage(this.currentlanguage);
      this.$store.commit('setLanguage', this.currentlanguage);
      location.reload();
    },
  },
};
</script>