<template>
  <v-form>
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="1">
          <p>{{ name }}</p>
        </v-col>
        <v-col cols="1.5">
          <v-text-field
            v-model="monthlyPrice"
            suffix="€"
            type="number"
            label="Monatspreis"
            @input="handleInput"
          >
            <template slot="append">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="success" dark v-bind="attrs" v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{ "Durchschnittlicher Nettopreis pro Monat." }}
                </span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="1.5">
          <v-text-field
            v-model="fixpreis"
            suffix="€"
            type="number"
            label="Einmalpreis"
            @input="handleInput"
          >
            <template slot="append">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="success" dark v-bind="attrs" v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{
                    "Initialer Startpreis bei Loseblattwerten oder Abos. Verkaufspreis bei Kursen oder Einmalfakturen."
                  }}
                </span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="1.5">
          <v-text-field
            v-model="vertragbezahlt"
            suffix="%"
            type="number"
            label="Wandlung Vertrag Bezahlt"
            @input="handleInput"
          >
            <template slot="append">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="success" dark v-bind="attrs" v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{ "Wandlung Anfo zu 1.Zahlung" }}
                </span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="1.5">
          <v-text-field
            v-model="monateImAbo"
            suffix="Monate"
            type="number"
            label="Monate im Abo (ab AboMax)"
            @input="handleInput"
          >
            <template slot="append">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="success" dark v-bind="attrs" v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{ "Monate im Vertrag ab AboMax/ 1. Tag im Abo." }}
                </span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="1.5">
          <v-text-field
            v-model="ausfall"
            suffix="%"
            type="number"
            label="Ausfallquote im laufenden Vertrag"
            @input="handleInput"
          >
            <template slot="append">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="success" dark v-bind="attrs" v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{ "Ausfallquote im laufenden bezahltem Vertrag." }}
                </span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="1.5">
          <v-text-field
            readonly
            outlined
            filled
            v-model="ngwResult"
            suffix="€"
            label="NGW (Simuliert)"
            @input="handleInput"
          >
            <template slot="append">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="success" dark v-bind="attrs" v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  {{ "Simulierter NGW." }}
                </span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  name: "Anfo",
  props: {
    value: {
      type: Object,
    },
    name: {
      type: String,
    },
  },
  created() {
    this.monthlyPrice = this.value.monthlyPrice;
    this.vertragbezahlt = this.value.vertragbezahlt;
    this.monateImAbo = this.value.monateImAbo;
    this.ausfall = this.value.ausfall;
    this.fixpreis = this.value.fixpreis;
  },
  watch: {
    value(value) {
      return value;
    },
  },
  computed: {
    ngwResult() {
      let ngw = 0;
      ngw =
        this.monthlyPrice
          * (this.vertragbezahlt * 0.01)
          * this.monateImAbo
          * (1 - this.ausfall  * 0.01)
          +
          this.fixpreis
          * (this.vertragbezahlt * 0.01)
      return ngw.toFixed(2);
    },
  },
  data() {
    return {
      monthlyPrice: 1,
      fixpreis: 1,
      vertragbezahlt: 1,
      monateImAbo: 1,
      ausfall: 0
    };
  },
  methods: {
    handleInput() {
      this.$emit("input", {
        monthlyPrice: this.monthlyPrice,
        fixpreis: this.fixpreis,
        vertragbezahlt: this.vertragbezahlt,
        monateImAbo: this.monateImAbo,
        ausfall: this.ausfall
      });
    },
  },
};
</script>
