<template>
  <v-container fluid>
    <v-row justify="center">
      <h1>Willkommen bei PX Data</h1>
    </v-row>
    <v-row justify="center">
      <p>Jeder Mitarbeiter, unabhängig von der Position, kann das Geschäft durch PX Data verstehen und verbessern.</p>
    </v-row>
    <v-row justify="center">
      <p>Dadurch erreichen wir ein rentableres Wachstum im Kerngeschäft und mit neuen digitalen Produkten, durch datengetriebene Entscheidungsprozesse im Marketing.</p>
    </v-row>

    <!-- arvato branch auth begin -->

    <!-- <div v-if="! $store.signedIn">
      <amplify-authenticator></amplify-authenticator>
    </div>  -->

    <!-- arvato branch auth end -->

  </v-container>
</template>

<script>
export default {
  name: "MainView",
};
</script>
