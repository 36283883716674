import moment from 'moment'
import 'moment/locale/de'  // without this line it didn't work

moment.locale('de');

export default function install (Vue) {
  Object.defineProperties(Vue.prototype, {
    $moment: {
      get () {
        return moment;
      }
    }
  })
}