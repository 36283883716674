<template>
  <BaseForm title="Neue Anfo Vergütung" @onConfirmClicked="onConfirmClicked">
    <form>
      <v-container>
        <v-row>
          <v-col cols="4">
            <v-autocomplete
              v-model="articleCode"
              :error-messages="articleCodeErrors"
              :items="articles"
              label="Artikel"
              item-value="id"
              :item-text="getArticleString"
              required
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-for="marketingTypeGroup in marketingTypeGroupList"
          :key="marketingTypeGroup.id"
        >
          <v-col cols="4">
            <v-switch
              :label="`Details zu ${marketingTypeGroup.code}`"
              v-model="marketingTypeGroup.show"
            >
            </v-switch>
          </v-col>
          <v-col cols="4" v-if="!marketingTypeGroup.show">
            <v-text-field
              v-model="marketingTypeGroup.price"
              :label="`${marketingTypeGroup.code} Preis`"
              number
            >
            </v-text-field>
          </v-col>
          <v-col cols="4" v-if="!marketingTypeGroup.show">
            <v-btn
              color="success"
              @click="
                setDefaultField(
                  marketingTypeGroup.price,
                  marketingTypeGroup.code
                )
              "
            >
              Setze Standardwert
            </v-btn>
          </v-col>
          <v-container v-if="marketingTypeGroup.show">
            <v-row>
              <v-col
                cols="3"
                v-for="item in marketingTypeListFiltered(
                  marketingTypeGroup.code
                )"
                :key="item.id"
              >
                <v-text-field
                  class="ml-4"
                  prefix="€"
                  v-model="item.price"
                  :label="`${item.code}`"
                  required
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="description"
              label="Beschreibung"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="validFrom"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="validFrom"
                  label="Gültig ab"
                  prepend-icon="mdi-calendar"
                  required
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="validFrom" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(validFrom)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-model="createdBy"
              label="Erstellt von"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </form>
  </BaseForm>
</template>

<script>
import { API } from "aws-amplify";
import { createOrderCompensation } from "@/graphql/mutations";
import {
  listMarketingTypes,
  listArticles,
  listMarketingTypeGroups,
} from "@/graphql/queries";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import BaseForm from "@/components/baseCrud/BaseForm.vue";

export default {
  name: "app",
  mixins: [validationMixin],
  components: {
    BaseForm,
  },
  data() {
    return {
      articleCode: "",
      price: 1000,
      marketingTypeList: [],
      marketingTypeGroupList: [],
      articles: [],
      validFrom: null,
      menu: false,
      description: "",
    };
  },
  validations: {
    articleCode: { required },
  },
  async created() {
    this.getMarketingTypeList();
    this.getMarketingTypeGroupList();
    this.getArticleList();
    this.setCreatedBy();
    this.createdBy = this.kurzel;
  },
  methods: {
    setDefaultField(price, marketingGroupCode) {
      const list = this.marketingTypeList
        .filter((item) => item.isOrderConsumption === true)
        .filter((item) => {
          return item.marketingTypeGroup
            ? item.marketingTypeGroup.code === marketingGroupCode
            : false;
        });
      list.forEach((item) => {
        item.price = price;
      });
    },
    getArticleString(item) {
      return `${item.code} - ${item.title} - (${item.number})`;
    },
    async createMethod(idInput, priceInput) {
      let {
        articleCode,
        description,
        orderCompensationMarketingTypeCdId,
        price,
        validFrom,
        createdBy,
      } = this;
      orderCompensationMarketingTypeCdId = idInput;
      price = priceInput;
      const input = {
        articleID: articleCode,
        description,
        marketingTypeID: orderCompensationMarketingTypeCdId,
        price,
        validFrom,
        createdBy,
      };
      return await API.graphql({
        query: createOrderCompensation,
        variables: { input: input },
      });
    },
    onConfirmClicked() {
      const promises = [];
      this.marketingTypeList.forEach((item) => {
        if (item.price) {
          promises.push(this.createMethod(item.id, item.price));
        }
      });
      Promise.all(promises).then(() => {
        this.$router.push({ name: "OrderCompensationOverviewList" });
      });
    },
    async getMarketingTypeList() {
      const marketingTypeList = await API.graphql({
        query: listMarketingTypes,
      });
      this.marketingTypeList = marketingTypeList.data.listMarketingTypes.items;
    },
    async getMarketingTypeGroupList() {
      const marketingTypeGroupList = await API.graphql({
        query: listMarketingTypeGroups,
      });
      this.marketingTypeGroupList =
        marketingTypeGroupList.data.listMarketingTypeGroups.items;
    },
    async getArticleList() {
      const articles = await API.graphql({
        query: listArticles,
        variables: {
          limit: 10000,
        },
      });
      this.articles = articles.data.listArticles.items;
    },
    setCreatedBy() {
      if (this.user) {
        this.createdBy = this.user.email;
      }
    },
    onCancelClicked() {
      this.$router.go(-1);
    },
    marketingTypeListFiltered(filter) {
      return this.marketingTypeList
        .filter((item) => item.isOrderConsumption === true)
        .filter((item) => {
          return item.marketingTypeGroup
            ? item.marketingTypeGroup.code === filter
            : false;
        });
    },
  },
  computed: {
    articleCodeErrors() {
      const errors = [];
      if (!this.$v.articleCode.$dirty) return errors;
      !this.$v.articleCode.maxLength &&
        errors.push("Code must be at most 10 characters long");
      !this.$v.articleCode.required && errors.push("Code is required.");
      return errors;
    },
    ...mapGetters(["user", "kurzel"]),
  },
};
</script>