<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="290">
        <v-card>
          <v-card-title class="headline">Eintrag löschen</v-card-title>

          <v-card-text>
            Willst du den unwiderruflich Eintrag wirklich löschen?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn tile color="grey darken-1" text @click="cancel()">
              Abbrechen
            </v-btn>

            <v-btn tile color="red darken-1" text @click="onDeleteClick()">
              Löschen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar v-model="showError" color="error" y="top" :timeout="timeout">
      {{ "Es ist ein  Fehler aufgetreten" }}
    </v-snackbar>
    <v-snackbar
      v-model="showSuccess"
      color="success"
      y="top"
      :timeout="timeout"
    >
      {{ "Der Tag wurde erfolgreich gelöscht" }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { API } from "aws-amplify";
import * as mutations from '@/graphql/mutations';

export default {
  data: () => ({
    dialog: false,
    showError: false,
    showSuccess: false,
    responseObj: null,
    timeout: 3000,
    id: null,
    _version: null,
  }),

  computed: {},

  methods: {
    onDeleteClick() {
      this.deleteItemById(this.id, this._version, this.deleteMutation).then(() => {
        this.dialog = false;
        this.$emit('refresh')
      });
    },
    async deleteItemById(id, _version, mutationName) {
      const inputObj = {
        id,
        _version,
      };
      await API.graphql({ query: mutations[mutationName], variables: {input: inputObj}});
    },
    show(id, _version, deleteMutation) {
      this.dialog = true;
      this.id = id;
      this.deleteMutation = deleteMutation;
      this._version = _version;
    },
    cancel() {
      this.dialog = false;
      this.$emit("dialogClose");
    },
  },
};
</script>
