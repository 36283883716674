import { I18n } from 'aws-amplify';

export const commonMixin = {
  methods: {
    t(value) {
      return I18n.get(value);
    },
    setTranslations(dict) {
      I18n.putVocabularies(dict);
    }
  }
};
