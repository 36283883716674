export const listNboRecBacktestIds = /* GraphQL */ `
  query ListNboRecBacktestResults(
    $filter: ModelNboRecBacktestResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNboRecBacktestResults(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bp_id
      }
      nextToken
      startedAt
    }
  }
`;