<template>
  <v-container fluid>
    <BaseTable
      :items="items"
      :headers="headers"
      :title="tableConfig.listHeader"
      :componentName="`${tableConfig.category}${tableConfig.tableName}`"
      :tableName="`${tableConfig.tableNamePlural}`"
      :editMutation="`update${tableConfig.tableName}`"
      :deleteMutation="`delete${tableConfig.tableName}`"
      :isDuplicateButton="tableConfig.isDuplicateButton"
      @refresh="updateList"
      @loadMore="onLoadMoreDataClicked"
      :loading="loading"
      :nextToken="nextToken"
      :sortingKey="sortingKey"
    />
  </v-container>
</template>

<script>
import { API } from "aws-amplify";
import * as queries from "@/graphql/queries";
import BaseTable from "@/components/BaseTable.vue";

export default {
  name: "DefaultTable",
  components: {
    BaseTable,
  },
  props: {
    config: {
      type: Array,
    },
    tableConfig: {
      type: Object,
    }
  },
  async created() {
    this.updateList();
  },
  methods: {
    updateList() {
      this.getList();
    },
    async onLoadMoreDataClicked() {
      this.loading = true;
      const queryName = this.tableConfig.query?this.tableConfig.query:
        `list${this.tableConfig.tableName}s`;
      const variables = this.tableConfig.variables?
        this.tableConfig.variables:{limit: 500};
      const response = await API.graphql({
        query: queries[queryName],
        variables: variables
      });
      this.items = this.items.concat(response.data[queryName].items);
      this.nextToken = response.data[queryName].nextToken;
      this.loading = false;
    },
    async getList() {
      this.loading = true;
      const queryName = this.tableConfig.query?this.tableConfig.query:
        `list${this.tableConfig.tableName}s`;
      const variables = this.tableConfig.variables?
        this.tableConfig.variables:{limit: 500};
      const response = await API.graphql({
        query: queries[queryName],
        variables: variables
      });
      this.items = response.data[queryName].items;
      this.nextToken = response.data[queryName].nextToken;
      this.loading = false;
    },
  },
  data() {
    return {
      items: [],
      nextToken: null,
      loading: true
    };
  },
  computed: {
    headers() {
      const returnObj = [];
      this.config.forEach((item) => {
        if (item.inTable) {
          if (item.fieldType === 'refDropdown')  {
            returnObj.push({
              text: item.name,
              value: item.referenceTableDisplay
            })
          }
          else if (item.fieldType === 'localRefDropdown') {
            returnObj.push({
              text: item.name,
              value: item.techName
            })
          } else {
            returnObj.push({
              text: item.name,
              value: item.techName
            })
          }
        }
      })
      return returnObj;
    },
    sortingKey() {
      if (this.tableConfig.sortingKey) {
        return this.tableConfig.sortingKey;
      }
      return 'cd';
    }
  }
};
</script>
