<template>
  <v-app id="app" light>
    <v-app-bar app flat light height="45">
      <Header />
    </v-app-bar>
    <!-- <div>{{ 'activ?'+this.reportsActive}}</div> -->
    <!-- <p>{{ this.added_items  }}</p> -->
    <v-navigation-drawer
      app
      permanent
      :mini-variant.sync="mini"
      color="#F5F5F5"
      :width="navWidth"
      left
      floating
    >
      <v-list-item nav>
        <router-link to="/" @click.native="closeReportsAndLeadgen()">
          <v-img
            contain
            :width="logoWidth"
            :height="logoHeight"
            style="margin-top: 5px"
            :src="logoPath"
            alt="Logo des VNR Verlags"
          />
        </router-link>
        <v-btn style="margin-left: 65px" text @click.stop="mini = !mini"
          ><v-icon right> mdi-chevron-left </v-icon></v-btn
        >
      </v-list-item>

      <v-list expand nav>
        <v-list-group
          v-for="item in filteredItems"
          :key="item.title"
          no-action
          v-model="item.active"
          :value="$route.path.startsWith(item.module)"
        >
          <template v-slot:activator>
            <span class="material-symbols-outlined">{{ item.action }}&nbsp;</span>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item style="padding-left: 10pt">
            <v-list-item-content style="overflow: hidden">
              <template>
                <v-treeview
                  activatable
                  hoverable
                  :items="added_items"
                  open-on-click
                  :active="reportsActive"
                  :open="reportsOpen"
                  style="overflow: hidden"
                >
                  <template slot="label" slot-scope="{ item }">
                    <v-tooltip right style="overflow: hidden">
                      <template v-slot:activator="{ on }">
                        <div
                          v-on="on"
                          @click="onClick(item)"
                          style="
                            width: 190px;
                            height: 40px;
                            line-height: 40px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                          "
                        >
                          {{ item.name }}
                        </div>
                      </template>
                      <span> {{ item.name }}</span>
                    </v-tooltip>
                  </template>
                </v-treeview>
              </template>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
      <!-- for debug  -->
      <!-- <div>{{ this.$router.currentRoute.path }}</div> -->
    </v-navigation-drawer>
    <v-main id="lateral">
      <v-container fluid>
        <router-view v-if="!showReports" />
        <Reports
          :title="rePortTitle"
          :embbedinglink="iframeLink"
          :description="reportDescription"
          :use_cases="use_cases"
          v-if="showReports"
        />
      </v-container>
    </v-main>
    <v-footer style="z-index: 1" absolute app dark color="secondary" />
  </v-app>
</template>

<script>
import Header from '@/components/menu/Header.vue';
import { mapGetters } from 'vuex';
import { API, I18n } from 'aws-amplify';
// import menuFile1 from '../src/assets/structure_output.json';
import Reports from '@/views/reports/Reports.vue';

export default {
  components: {
    Header,
    Reports,
  },
  computed: {
    ...mapGetters(['signedIn', 'user', 'language']),
    filteredItems() {
      return this.items.filter(
        (item) => !item.signedIn || (this.signedIn && this.filterRoles(item.items).length > 0)
      );
    },
    logoPath() {
      if (this.mini) {
        return require('@/assets/logo.png');
      } else {
        if (process.env.VUE_APP_ENV === 'dev') {
          return require('@/assets/logo-plx-dev.png');
        } else {
          return require('@/assets/logo-plx.png');
        }
      }
    },
    logoWidth() {
      if (this.mini === true) {
        return '27';
      }
      return '170';
    },
    logoHeight() {
      if (this.mini === true) {
        return '27';
      }
      return '50';
    },
    navWidth() {
      if (this.mini === true) {
        return '60';
      }
      return '320';
    },
  },
  created() {
    document.title = 'PX Data';
    I18n.setLanguage(this.language);
  },
  mounted() {
    this.getReportMenu();
  },
  name: 'DefaultLayout',
  data() {
    return {
      mini: false,
      drawer: true,
      showReports: false,
      reportsActive: [],
      reportsOpen: [],
      rePortTitle: 'Report',
      iframeLink: '',
      use_cases: [],
      reportDescription: '',
      report_menus: [],
      items: [
        {
          action: 'trending_up',
          signedIn: true,
          active: true,
          title: 'Reporting',
          module: 'Reports',
          to: 'Reports',
          items: [
            {
              link: 'Reports',
              roles: ['All'],
            },
          ],
        },
      ],
      added_items: [],
    };
  },
  methods: {
    getReportMenu: function () {
      this.loading = true;
      API.get('datauiRestAPI', '/reportMenu', {})
        .then((result) => {
          let temp = JSON.parse(result);
          this.createReportMenu(temp);
          // console.log('DEBUG REPORTS: '+Object.keys(temp));
        })
        .catch((err) => {
          console.log(err);
        });
      this.loading = false;
    },
    hasRole(role) {
      if (role.includes('All')) {
        return true;
      }
      if (!this.user || !this.user['custom:group']) {
        return false;
      }
      return this.user['custom:group'].includes(role);
    },
    filterRoles(itemArray) {
      const roles = itemArray[0].roles;
      if (!roles) {
        return itemArray;
      }
      return itemArray.filter((item) => item.roles.some((role) => this.hasRole(role)));
    },
    onClick(item) {
      if (item.to) {
        this.showReports = true;
        this.rePortTitle = item.name;
        this.iframeLink = item.to;
        this.reportDescription = item.description;
        this.use_cases = item.use_cases;
        let query = item.to.split('dashboards/')[1];
        this.$router
          .push({ path: '/reports/Reports/', query: { dashboard: query } })
          .catch(() => {});
        console.log('click: ' + this.iframeLink.split('dashboards/')[1]);
      }
    },
    closeReportsAndLeadgen() {
      this.showReports = false;
      this.reportsActive = [];
      this.reportsOpen = [];
    },
    randomString() {
      let length = 6 + Math.floor(Math.random() * 4);
      let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let result = '';
      for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
      return result;
    },
    treeMenuItem(parent) {
      let temp = parent.structure_children;
      let menuItems = [];
      for (const [key, value] of Object.entries(temp)) {
        // console.log('debug key: '+key);
        let childArr = [];
        value.structure_children.map((i) => {
          let childObj = {
            id: this.randomString(),
            name: Object.keys(i)[0],
            to: Object.values(i)[0].embbeding_link,
            description: Object.values(i)[0].description,
            use_cases: Object.values(i)[0].use_cases,
          };
          childArr.push(childObj);
        });
        let obj = {
          id: this.randomString(),
          name: key,
          children: childArr,
        };
        menuItems.push(obj);
      }
      return menuItems;
    },
    createReportMenu(menuFile) {
      let autoCreateMenus = [];
      Object.keys(menuFile).forEach((i) => {
        let obj = {
          id: this.randomString(),
          name: i,
          action: 'table',
          signedIn: true,
          active: true,
          title: i,
          // embbeding_link: menuFile[i].embbeding_link,
          module: 'Reports',
          // to: 'Reports',
          to: menuFile[i].embbeding_link,
          items: [
            {
              link: 'Reports',
              roles: ['All'],
            },
          ],
          children: this.treeMenuItem(menuFile[i]),
        };
        autoCreateMenus.push(obj);
      });
      this.added_items = autoCreateMenus;
      // console.log(this.added_items);
    },
  },
};
</script>

<style>
* {
  --amplify-primary-color: #0168a5;
  --amplify-primary-tint: #1671a5;
  --amplify-primary-shade: #024d79;
}

.no-underline {
  text-decoration: none !important;
}
.material-symbols-outlined {
  color: #707070;
}
</style>
