<template>
  <v-container fluid>
    <v-data-table
      v-model="selected"
      :headers="extendedHeader"
      :items="filteredItems"
      item-key="id"
      show-select
      class="elevation-1"
      single-select
      :search="search"
      hide-default-footer
      :sort-by="sortingKey"
      :items-per-page="-1"
      :loading="loading"
      loading-text="Wir suchen die Daten für dich raus."
      no-data-text="Es existiert noch kein Eintrag."
    >
      <template v-slot:item.price="{ item }">
        {{ (Math.round(item.price * 100) / 100).toFixed(2) }} €
      </template>
      <template v-slot:item.priceThreshold="{ item }">
        {{ (Math.round(item.priceThreshold * 100) / 100).toFixed(2) }} €
      </template>
      <template v-slot:item.monthThreshold="{ item }">
        {{ (Math.round(item.monthThreshold * 100) / 100).toFixed(0) }} Monate
      </template>
      <template v-slot:item.cpo="{ item }">
        {{ (Math.round(item.cpo * 100) / 100).toFixed(2) }} €
      </template>
      <template v-slot:item.totalCost="{ item }">
        {{ (Math.round(item.totalCost * 100) / 100).toFixed(2) }} €
      </template>
      <template v-slot:item.costPerLead="{ item }">
        {{ (Math.round(item.costPerLead * 100) / 100).toFixed(2) }} €
      </template>
      <template v-slot:item.validFrom="{ item }">
        {{ $moment(item.validFrom).format("YYYY-MM-DD") }}
      </template>
      <template v-slot:item.validTill="{ item }">
        {{ $moment(item.validTill).format("YYYY-MM-DD") }}
      </template>
      <template v-slot:item.fromDate="{ item }">
        {{ $moment(item.fromDate).format("YYYY-MM-DD") }}
      </template>
      <template v-slot:item.untilDate="{ item }">
        {{
          item.untilDate ? $moment(item.untilDate).format("YYYY-MM-DD") : "-"
        }}
      </template>
      <template v-slot:item.marketingTypeCd="{ item }">
        {{ item.marketingTypeCd.code }} ({{ item.marketingTypeCd.description }})
      </template>
      <!-- <template v-slot:item.affiliateType="{ item }">
        {{ item.affiliateType.cd }} ({{ item.affiliateType.description }})
      </template>
      <template v-slot:item.agency="{ item }">
        {{ item.agency.cd }} ({{ item.agency.name }})
      </template>
      <template v-slot:item.network="{ item }">
        {{ item.network.cd }} ({{ item.network.name }})
      </template>
      <template v-slot:item.targetGroup="{ item }">
        {{ item.targetGroup.name }})
      </template>
      <template v-slot:item.subpublisher="{ item }">
        {{ item.subpublisher.cd }} ({{ item.subpublisher.name }})
      </template>
      <template v-slot:item.newsletter="{ item }">
        {{ item.newsletter.cd }} ({{ item.newsletter.name }})
      </template> -->
      <template v-slot:item.article="{ item }">
        {{ item.article && item.article.code }} -
        {{ item.article && item.article.title }} ({{
          item.article && item.article.number
        }})
      </template>
      <template v-slot:item.description="{ item }">
        {{
          item.description === null ? "keine Beschreibung" : item.description
        }}
      </template>
      <template v-slot:item.OneStepArticleMapping="{ item }">
        {{
          `${
            item.OneStepArticleMapping
              ? item.OneStepArticleMapping.mandatorCode
              : ""
          } -
            ${
              item.OneStepArticleMapping
                ? item.OneStepArticleMapping.articleCode
                : ""
            }`
        }}
      </template>
      <template v-slot:item.createdBy="{ item }">
        {{
          `${item.createdBy} (${$moment(item.createdAt).format("YYYY-MM-DD")})`
        }}
      </template>
      <template v-slot:item.isEmf="{ item }">
        <v-simple-checkbox v-model="item.isEmf" disabled></v-simple-checkbox>
      </template>
      <template v-slot:item.isIndividualized="{ item }">
        <v-simple-checkbox
          v-model="item.isIndividualized"
          disabled
        ></v-simple-checkbox>
      </template>
      <template v-slot:item.isApproved="{ item }">
        <v-simple-checkbox
          v-model="item.isApproved"
          disabled
        ></v-simple-checkbox>
      </template>
      <template v-slot:item.isMandatory="{ item }">
        <v-simple-checkbox
          v-model="item.isMandatory"
          disabled
        ></v-simple-checkbox>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title> {{ title }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Suche"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer />
          <span
            class="mx-2"
            :style="itemCout >= 500 ? 'color: red; font-weight: bold;' : ''"
          >
            {{ `Anzahl: ${itemCout}` }}
          </span>
          <v-btn tile class="ma-3" color="success" @click="onNewClicked">
            <v-icon left> mdi-plus </v-icon>
            Neu
          </v-btn>
          <v-btn
            v-if="isApprovedButton"
            tile
            :disabled="!selected.length"
            class="ma-3"
            color="primary"
            @click="onApproveClicked"
          >
            <v-icon left> mdi-check-decagram </v-icon>
            Freigeben
          </v-btn>
          <v-btn
            tile
            v-if="isDuplicateButton"
            :disabled="!selected.length"
            class="ma-3"
            color="primary"
            @click="onDuplicateClicked"
          >
            <v-icon left> mdi-content-copy </v-icon>
            Duplizieren
          </v-btn>
          <v-btn
            tile
            :disabled="!selected.length"
            class="ma-3"
            color="warning"
            @click="onEditClicked"
          >
            <v-icon left> mdi-pencil </v-icon>
            Editieren
          </v-btn>
          <v-btn
            tile
            :disabled="!selected.length"
            class="ma-3"
            color="error"
            @click="onDeleteClick"
          >
            <v-icon left> mdi-delete </v-icon>
            Löschen
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:footer>
        <v-btn
          tile
          class="ma-4"
          color="error"
          v-if="nextToken"
          @click="loadMore"
        >
          <v-icon left> mdi-view-grid-plus</v-icon>
          Mehr Daten Nachladen
        </v-btn>
      </template>
    </v-data-table>
    <DeleteModual @refresh="refresh" ref="deleteAnfoModual" />
    <ApproveModual @refresh="refresh" ref="approveAnfoModual" />
  </v-container>
</template>

<script>
import DeleteModual from "@/components/DeleteModual.vue";
import ApproveModual from "@/components/ApproveModal.vue";

export default {
  name: "BaseTable",
  props: {
    headers: {
      type: Array,
    },
    items: {
      type: Array,
    },
    title: {
      type: String,
    },
    componentName: {
      type: String,
      default: "",
    },
    nextToken: {
      type: String,
      default: null,
    },
    sortingKey: {
      type: String,
      default: "cd",
    },
    itemCount: {
      type: Number,
      default: 0,
    },
    deleteMutation: {
      type: String,
    },
    editMutation: {
      type: String,
      default: "nicht definiert",
    },
    loading: {
      type: Boolean,
    },
    isApprovedButton: {
      type: Boolean,
      default: false,
    },
    isDuplicateButton: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DeleteModual,
    ApproveModual,
  },
  computed: {
    filteredItems() {
      return this.items;
    },
    itemCout() {
      return this.items.length;
    },
    extendedHeader() {
      return this.headers.concat([{ text: "Erstellt", value: "createdBy" }]);
    },
    createLink() {
      return `${this.componentName}Create`;
    },
    editLink() {
      return `${this.componentName}Update`;
    },
    duplicateLink() {
      return `${this.componentName}Duplicate`;
    },
  },
  methods: {
    onDuplicateClicked() {
      const item = this.selected[0];
      console.log(item);
      this.$router.push({
        name: this.duplicateLink,
        params: { id: item.id },
      });
    },
    loadMore() {
      this.$emit("loadMore");
    },
    onNewClicked() {
      this.$router.push({
        name: this.createLink,
      });
    },
    onApproveClicked() {
      const items = this.selected[0];

      this.$refs.approveAnfoModual.show(items, this.editMutation);
    },
    onEditClicked() {
      const item = this.selected[0];
      this.$router.push({
        name: this.editLink,
        params: { id: item.id },
      });
    },
    onDeleteClick() {
      const id = this.selected[0].id;
      const _version = this.selected[0]._version;

      this.$refs.deleteAnfoModual.show(id, _version, this.deleteMutation);
    },
    refresh() {
      this.$emit("refresh");
    },
  },
  data() {
    return {
      selected: [],
      search: "",
    };
  },
};
</script>

<style scoped>
tr:nth-child(even) {
  background-color: #f2f2f2;
}
</style>
