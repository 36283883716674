<template>
  <BaseForm :title="tableConfig.createHeader" @onConfirmClicked="onConfirmClicked">
    <form>
      <v-container>
        <v-row>
          <v-col :cols="field.columnWidth || 6" v-for="(field, i) in config" :key="i">
            <BaseField :field="field" v-model="data[field.techName]" @setDefaultValue="setDefaultValue" />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="data.createdBy"
              label="Ersteller"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </form>
  </BaseForm>
</template>

<script>
import { API } from "aws-amplify";
import BaseForm from "@/components/baseCrud/BaseForm.vue";
import BaseField from "@/components/baseCrud/BaseField.vue";
import { mapGetters } from "vuex";
import * as mutations from "@/graphql/mutations";

export default {
  name: "app",

  components: {
    BaseForm,
    BaseField,
  },
  props: {
    tableConfig: {
      type: Object,
    },
    config: {
      type: Array,
    },
  },
  data() {
    return {
      data: {},
    };
  },
  async created() {
    this.setDefault();
  },
  methods: {
    capitalize(s) {
      return s[0].toUpperCase() + s.slice(1);
    },
    lowerCase(s) {
      return s[0].toLowerCase() + s.slice(1);
    },
    setDefaultValue(value, field) {
      this.data[field] = value;
      this.$forceUpdate();
    },
    async setDefault() {
      const me = this;
      this.config.forEach((row) => {
        if (row && row.default) {
          if (row.default === 'lastMonth') {
            me.data[row["techName"]] = this.$moment().subtract(2,'months').endOf('month').add(1, 'd').format("YYYY-MM-DD");
          } else {
            me.data[row["techName"]] = row.default;
          }
        }
      });
      me.data.createdBy = this.kurzel;
    },
    async createMethod() {
      let saveData = {};
      this.config.filter((item) => {
        if (item.onCreate) {
          const name = item["techName"];
          let fieldName =
            item.fieldType === "refDropdown"
              ? `${name}${this.capitalize(this.tableConfig.tableNamePlural)}Id`
              : name;

          if (item.refType === "hasOne") {
            fieldName = `${this.lowerCase(
              this.tableConfig.tableName
            )}${this.capitalize(name)}Id`;
          }
          if (item.refType === "hasOneOld") {
            fieldName = `${name}ID`;
          }
          saveData[fieldName] = this.convertData(this.data[name]);
        }
      });
      saveData['createdBy'] = this.data.createdBy;
      await API.graphql({
        query: mutations[`create${this.tableConfig.tableName}`],
        variables: { input: saveData },
      });
      this.$router.push({ name: this.tableConfig.listRoute });
      this.$emit("refresh");
    },
    onConfirmClicked() {
      this.createMethod();
    },
    convertData(value) {
      if (value && this.isGoodDate(value)) {
        return this.$moment(value, 'YYYY-MM-DD', "de").format("YYYY-MM-DD");
      }
      return value;
    },
    isGoodDate(date) {
      const date_regex = /^\d{2}(\.|-)\d{2}(\.|-)\d{4}/;
      return date_regex.test(date);
    },
  },
  computed: {
    ...mapGetters(["user", "kurzel"]),
  },
};
</script>