<template>
  <v-container fluid>
    <BaseTable
      :items="items"
      :headers="headers"
      title="Überschreibung: Preis"
      componentName="NgwOverwriteArticlePrice"
      :loading="loading"
      :editMutation="'updateOverwriteArticlePrice'"
      :deleteMutation="'deleteOverwriteArticlePrice'"
      @refresh="updateList"
      isApprovedButton
    />
  </v-container>
</template>

<script>
import { API } from "aws-amplify";
import { listOverwriteArticlePrices } from "@/graphql/queries";
import BaseTable from "@/components/BaseTable.vue";

export default {
  name: "Anfo",
  components: {
    BaseTable,
  },
  async created() {
    this.getList();
  },
  methods: {
    updateList() {
      this.getList();
    },
    async getList() {
      this.loading = true;
      const response = await API.graphql({
        query: listOverwriteArticlePrices,
      });
      this.items = response.data.listOverwriteArticlePrices.items;
      this.loading = false;
    },
  },

  data() {
    return {
      headers: [
        { text: "Artikel", value: "article.code" },
        { text: "Grenztyp", value: "minMax" },
        { text: "Preistyp", value: "type" },
        { text: "Preisgrenze", value: "priceThreshold" },
        { text: "Grund", value: "reason" },
        { text: "Gültig von", value: "validFrom" },
        { text: "Gültig bis", value: "validTill" },
        { text: "Freig. Verlag", value: "approvedPublisherBy" },
        { text: "Freig. Controll.", value: "approvedControllingBy" },
      ],
      items: [],
      loading: true,
    };
  },
};
</script>
