<template>
  <v-form>
  <v-container fluid>
      <v-row align="center" justify="center">
        <a target="_blank" rel="noopener noreferrer" href="https://confluence.vnr.de/display/KW/NGW-Simulator">Anleitung in Confluence</a>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="2">
          <v-text-field
            label="Produkt"
            v-model="product"
            v-if="product"
            readonly
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            label="Werbeweg"
            v-model="marketingtype"
            v-if="marketingtype"
            readonly
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            label="Kondition"
            v-model="condition"
            v-if="condition"
            readonly
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-btn class="mx-2" @click="removeNgw" label="NGW Entfernen" color="error"> - </v-btn>
        <v-btn class="mx-2" @click="addNgw" label="NGW Hinzufügen" color="success"> + </v-btn>
      </v-row>
      <ngw-element name="NGW A" v-model="ngw1"/>
      <v-divider class="my-2" />
      <ngw-element v-if="showNgwNumber >= 1" name="NGW B" v-model="ngw2"/>
      <v-divider v-if="showNgwNumber >= 1" class="my-2" />
      <ngw-element v-if="showNgwNumber >= 2" name="NGW C" v-model="ngw3"/>
      <v-divider v-if="showNgwNumber >= 2" class="my-2" />
      <ngw-element v-if="showNgwNumber >= 3" name="NGW D" v-model="ngw4"/>
      <v-divider v-if="showNgwNumber >= 3" class="my-2" />
      <v-row>
        <v-col cols="2">
          <v-text-field v-model="anfos" suffix="Stück" label="Anfos">
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="ngw1sum"
            readonly
            filled
            outlined
            suffix="€"
            label="NGW A Summe"
          >
          </v-text-field>
        </v-col>
        <v-col cols="2" v-if="showNgwNumber >= 1">
          <v-text-field
            v-model="ngw2sum"
            readonly
            filled
            outlined
            suffix="€"
            label="NGW B Summe"
          >
          </v-text-field>
        </v-col>
        <v-col cols="2" v-if="showNgwNumber >= 2">
          <v-text-field
            v-model="ngw3sum"
            readonly
            filled
            outlined
            suffix="€"
            label="NGW C Summe"
          >
          </v-text-field>
        </v-col>
        <v-col cols="2" v-if="showNgwNumber >= 3">
          <v-text-field
            v-model="ngw4sum"
            readonly
            filled
            outlined
            suffix="€"
            label="NGW D Summe"
          >
          </v-text-field>
        </v-col>
        <v-col cols="2" v-if="showNgwNumber >= 1">
          <v-text-field
            v-model="ngwABDiff"
            readonly
            filled
            outlined
            suffix="€"
            label="Differenz A-B"
          >
          </v-text-field>
        </v-col>
        <v-col cols="2" v-if="showNgwNumber >= 1">
          <v-text-field
            v-model="ngwABDiffSum"
            readonly
            filled
            outlined
            suffix="€"
            label="Differenz A-B (Summe)"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import './ngwElement.vue'
import NgwElement from "./ngwElement.vue";

export default {
  name: "Anfo",
  components: {
    NgwElement,
  },
  created() {
    if (this.vertragbezahltParam) {
      this.setData();
    }
  },
  watch: {
  },
  computed: {
    ngw1_result() {
      let ngw = 0;
      ngw =
        this.ngw1.monthlyPrice *
          (this.ngw1.vertragbezahlt * 0.01) *
          this.ngw1.monateImAbo *
          (1 - (this.ngw1.ausfall * 0.01)) +
        this.ngw1.fixpreis * (this.ngw1.vertragbezahlt * 0.01);
      return ngw.toFixed(2);
    },
    ngw2_result() {
      let ngw = 0;
      ngw =
        this.ngw2.monthlyPrice *
          (this.ngw2.vertragbezahlt * 0.01) *
          this.ngw2.monateImAbo *
          (1 - (this.ngw2.ausfall * 0.01)) +
        this.ngw2.fixpreis * (this.ngw2.vertragbezahlt * 0.01);
      return ngw.toFixed(2);
    },
    ngw3_result() {
      let ngw = 0;
      ngw =
        this.ngw3.monthlyPrice *
          (this.ngw3.vertragbezahlt * 0.01) *
          this.ngw3.monateImAbo *
          (1 - (this.ngw3.ausfall * 0.01)) +
        this.ngw3.fixpreis * (this.ngw3.vertragbezahlt * 0.01);
      return ngw.toFixed(2);
    },
    ngw4_result() {
      let ngw = 0;
      ngw =
        this.ngw4.monthlyPrice *
          (this.ngw4.vertragbezahlt * 0.01) *
          this.ngw4.monateImAbo *
          (1 - (this.ngw4.ausfall * 0.01)) +
        this.ngw4.fixpreis * (this.ngw4.vertragbezahlt * 0.01);
      return ngw.toFixed(2);
    },
    ngw1sum() {
      return (this.anfos * this.ngw1_result).toFixed(2);
    },
    ngw2sum() {
      return (this.anfos * this.ngw2_result).toFixed(2);
    },
    ngw3sum() {
      return (this.anfos * this.ngw3_result).toFixed(2);
    },
    ngw4sum() {
      return (this.anfos * this.ngw4_result).toFixed(2);
    },
    ngwABDiff() {
      return (this.ngw2_result - this.ngw1_result).toFixed(2);
    },
    ngwABDiffSum() {
      return (this.ngw2sum - this.ngw1sum).toFixed(2);
    },
    flexpreisParam() {
      return parseFloat(this.$route.query.flexpreis, 10).toFixed(2);
    },
    fixpreisParam() {
      return parseFloat(this.$route.query.fixpreis, 10).toFixed(2);
    },
    vertragbezahltParam() {
      if (!this.$route.query.vertragbezahlt) {
        return false;
      }
      return parseFloat(this.$route.query.vertragbezahlt * 100, 10).toFixed(1);
    },
    monateimaboParam() {
      return parseFloat(this.$route.query.monateimabo, 10).toFixed(1);
    },
    zahlquoteimaboParam() {
      return parseFloat(this.$route.query.zahlquoteimabo * 100, 10).toFixed(1);
    },
    productParam() {
      return this.$route.query.product;
    },
    marketingtypeParam() {
      return this.$route.query.marketingtype;
    },
    conditionParam() {
      return this.$route.query.condition;
    },
  },
  data() {
    return {
      showNgwNumber: 0,
      ngw1: {
        monthlyPrice: 11.0,
        fixpreis: 5.0,
        vertragbezahlt: 40,
        monateImAbo: 12,
        ausfall: 7,
      },
      ngw2: {
        monthlyPrice: 11.0,
        fixpreis: 5.0,
        vertragbezahlt: 40,
        monateImAbo: 12,
        ausfall: 3,
      },
      ngw3: {
        monthlyPrice: 11.0,
        fixpreis: 5.0,
        vertragbezahlt: 40,
        monateImAbo: 12,
        ausfall: 3,
      },
      ngw4: {
        monthlyPrice: 11.0,
        fixpreis: 5.0,
        vertragbezahlt: 40,
        monateImAbo: 12,
        ausfall: 3,
      },
      anfos: 100,
      product: null,
      marketingtype: null,
      condition: null,
    };
  },
  methods: {
    addNgw() {
      this.showNgwNumber = this.showNgwNumber + 1;
      this.showNgwNumber = this.showNgwNumber >= 3 ? 3 : this.showNgwNumber;
    },
    removeNgw() {
      this.showNgwNumber = this.showNgwNumber - 1;
      this.showNgwNumber = this.showNgwNumber < 0 ? 0 : this.showNgwNumber;
    },
    setData() {
      this.ngw1.monthlyPrice = this.flexpreisParam;
      this.ngw1.fixpreis = this.fixpreisParam;
      this.ngw1.vertragbezahlt = this.vertragbezahltParam;
      this.ngw1.monateImAbo = this.monateimaboParam;
      this.ngw1.ausfall = 100 - this.zahlquoteimaboParam;

      this.ngw2.monthlyPrice = this.flexpreisParam;
      this.ngw2.fixpreis = this.fixpreisParam;
      this.ngw2.vertragbezahlt = this.vertragbezahltParam;
      this.ngw2.monateImAbo = this.monateimaboParam;
      this.ngw2.ausfall = 100 - this.zahlquoteimaboParam;

      this.ngw3.monthlyPrice = this.flexpreisParam;
      this.ngw3.fixpreis = this.fixpreisParam;
      this.ngw3.vertragbezahlt = this.vertragbezahltParam;
      this.ngw3.monateImAbo = this.monateimaboParam;
      this.ngw3.ausfall = 100 - this.zahlquoteimaboParam;

      this.ngw4.monthlyPrice = this.flexpreisParam;
      this.ngw4.fixpreis = this.fixpreisParam;
      this.ngw4.vertragbezahlt = this.vertragbezahltParam;
      this.ngw4.monateImAbo = this.monateimaboParam;
      this.ngw4.ausfall = 100 - this.zahlquoteimaboParam;

      this.product = this.productParam;
      this.marketingtype = this.marketingtypeParam;
      this.condition = this.conditionParam;
    },
  },
};
</script>
