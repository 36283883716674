<template>
  <v-container fluid class="maxWidth">
    <v-row align="center" justify="center">
      <v-col md="12" align="center" justify="center">
        <h1 class="display-1 ma-3">Data sagt Danke :-)</h1>
        <p class="body-1">
          Du hast uns sehr geholfen den NBO weiter zu verbessern.
          <br />
        </p>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col md="12" align="center" justify="center">
        <h2 class="ma-3">Verbesserungsvorschlag</h2>
        <p class="body-1">
          Falls du einen Verbesserungsvorschlag hast, schreib uns gerne per
          Teams. Unsere Kürzel sind RoB, Pcx (Nur Englisch)
        </p>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <router-link :to="{ name: 'NboSurveyLanding' }" class="no-underline">
        <v-btn class="ma-4" color="success">
          <v-icon left>mdi-food-apple</v-icon>
          Nochmal 5 Bewerten
        </v-btn>
      </router-link>
    </v-row>
  </v-container>
</template>

<script>
import { commonMixin } from '@/mixins/commonMixin';

export default {
  mixins: [commonMixin],
  components: {},
  computed: {},
  created() {
    this.setTranslations({
        'de': {
            'languageButtonText': "Sprache bestätigen",
        },
        'en': {
            'languageButtonText': "Use language",
        }
    })
  },
};
</script>