<template>
  <BaseForm
    title="Anfo Vergütung korrigieren"
    @onConfirmClicked="onConfirmClicked"
  >
    <form>
      <v-text-field v-model="data.price" label="Preis" required></v-text-field>
      <v-autocomplete
        v-model="data.article"
        :error-messages="articleCodeErrors"
        :items="articles"
        label="Artikel"
        item-value="id"
        disabled
        :item-text="getArticleString"
        required
      ></v-autocomplete>
      <v-select
        v-model="data.marketingType"
        label="Werbeweg"
        :items="marketingTypeList"
        item-value="id"
        item-text="code"
        disabled
        required
      ></v-select>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="data.validFrom"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="data.validFrom"
            label="Gültig ab"
            prepend-icon="mdi-calendar"
            required
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="data.validFrom" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(data.validFrom)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-text-field
        v-model="data.description"
        label="Beschreibung"
        required
      ></v-text-field>
    </form>
  </BaseForm>
</template>

<script>
import { API } from "aws-amplify";
import { updateOrderCompensation } from "@/graphql/mutations";
import {
  listMarketingTypes,
  listArticles,
  getOrderCompensation,
} from "@/graphql/queries";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import BaseForm from "@/components/baseCrud/BaseForm.vue";

export default {
  name: "app",
  mixins: [validationMixin],
  components: {
    BaseForm,
  },
  data() {
    return {
      data: {},
      menu: false,
      marketingTypeList: [],
      articles: [],
    };
  },
  validations: {
    articleCode: { required, maxLength: maxLength(3) },
  },
  async created() {
    this.getMarketingTypeList();
    this.getArticleList();
    this.getCurrentItemById(this.currentId);
  },
  methods: {
    getArticleString(item) {
      return `${item.code} - ${item.title}`;
    },
    async updateMethod() {
      const input = {
        articleID: this.data.article.id,
        description: this.data.description,
        marketingTypeID: this.data.marketingType.id,
        price: this.data.price,
        validFrom: this.data.validFrom,
        id: this.currentId,
        _version: this.data["_version"],
      };
      await API.graphql({
        query: updateOrderCompensation,
        variables: { input: input },
      });
      this.$router.push({ name: "OrderCompensationOverviewList" });
    },
    async getCurrentItemById(id) {
      const response = await API.graphql({
        query: getOrderCompensation,
        variables: { id },
      });
      this.data = response.data.getOrderCompensation;
    },
    onConfirmClicked() {
      this.updateMethod();
    },
    async getMarketingTypeList() {
      const marketingTypeList = await API.graphql({
        query: listMarketingTypes,
      });
      this.marketingTypeList = marketingTypeList.data.listMarketingTypes.items;
    },
    async getArticleList() {
      const articles = await API.graphql({
        query: listArticles,
        variables: {
          limit: 10000,
        },
      });
      this.articles = articles.data.listArticles.items;
    },
  },
  computed: {
    currentId() {
      return this.$route.params.id;
    },
    articleCodeErrors() {
      const errors = [];
      if (!this.$v.articleCode.$dirty) return errors;
      !this.$v.articleCode.maxLength &&
        errors.push("Code must be at most 10 characters long");
      !this.$v.articleCode.required && errors.push("Code is required.");
      return errors;
    },
  },
};
</script>