<template>
  <v-container fluid>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="290">
        <v-card>
          <v-card-title class="headline">Eintrag Freigeben</v-card-title>

          <v-card-text>
            Willst du Wert wirklich freigeben?
          </v-card-text>

            <v-checkbox
              class="ma-3"
              v-model="isApprovedPublisher"
              label="Fachverlag"
              color="orange"
              hide-details
            ></v-checkbox>
            <v-checkbox
              class="ma-3"
              v-model="isApprovedControlling"
              label="Controlling"
              color="red darken-3"
              hide-details
            ></v-checkbox>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn tile color="grey darken-1" text @click="cancel()">
              Abbrechen
            </v-btn>

            <v-btn tile color="red darken-1" text @click="onApproveClicked()">
              Freigeben
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar v-model="showError" color="error" y="top" :timeout="timeout">
      {{ "Es ist ein  Fehler aufgetreten" }}
    </v-snackbar>
    <v-snackbar
      v-model="showSuccess"
      color="success"
      y="top"
      :timeout="timeout"
    >
      {{ "Der Tag wurde erfolgreich gelöscht" }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { API } from "aws-amplify";
import * as mutations from '@/graphql/mutations';
import { mapGetters } from "vuex";

export default {
  data: () => ({
    dialog: false,
    showError: false,
    showSuccess: false,
    responseObj: null,
    timeout: 3000,
    id: null,
    _version: null,
    isApprovedControlling: false,
    isApprovedPublisher: false,
    items: {}
  }),

  computed: {
    ...mapGetters(["kurzel", "user"]),
  },
  watch: {
    items(value) {
      this.isApprovedControlling = !!value.approvedControllingBy
      this.isApprovedPublisher = !!value.approvedPublisherBy
    }
  },
  methods: {
    onApproveClicked() {
      this.updateItemById(this.id, this._version, this.mutation, this.items).then(() => {
        this.dialog = false;
        this.$emit('refresh')
      });
    },
    async updateItemById(id, _version, mutationName, items) {
      const inputObj = {
        id,
        _version,
        approvedControllingBy: this.getNewApprove(this.kurzel, items.approvedControllingBy, this.isApprovedControlling),
        approvedPublisherBy: this.getNewApprove(this.kurzel, items.approvedPublisherBy, this.isApprovedPublisher)
      };
      await API.graphql({ query: mutations[mutationName], variables: {input: inputObj}});
    },
    show(items, mutation) {
      this.dialog = true;
      this.id = items.id;
      this.items = items;
      this.mutation = mutation;
      this._version = items._version;
    },
    getNewApprove(kurzel, old, trigger) {
      if (!trigger) {
        return null;
      }
      if (!!old === trigger) {
        return old;
      }
      return this.kurzel;

    },
    cancel() {
      this.dialog = false;
      this.$emit("dialogClose");
    },
  },
};
</script>
