<template>
  <BaseForm
    title="Monate im Abo Überschreibung bearbeiten"
    @onConfirmClicked="onConfirmClicked"
  >
    <form>
      <v-container fluid>
        <v-row align="center" justify="center">
          <v-col cols="3">
            <v-select
              v-model="data.articleID"
              label="Artikel"
              item-value="id"
              item-text="code"
              :items="articleList"
              :error-messages="articleErrors"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="data.marketingTypeID"
              label="Werbeweg"
              item-value="id"
              item-text="code"
              :items="marketingTypeList"
              :error-messages="marketingTypeErrors"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="data.conditionID"
              label="Kondition"
              item-value="id"
              item-text="name"
              :items="contractConditionGroupList"
              :error-messages="contractConditionGroupErrors"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="data.minMax"
              label="Grenztyp"
              :items="minMaxList"
              required
              item-value="code"
              item-text="name"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="data.monthThreshold"
              :error-messages="monthThresholdErrors"
              :counter="10"
              label="Monatsgrenze"
              required
              suffix="Monate"
              @input="$v.data.monthThreshold.$touch()"
              @blur="$v.data.monthThreshold.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="6">
            <v-text-field
              v-model="data.reason"
              :error-messages="reasonErrors"
              label="Grund (Freitext)"
              required
              @input="$v.data.reason.$touch()"
              @blur="$v.data.reason.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" class="ma-3">
          <BaseField
            :field="fieldDef[0]"
            v-model="data[fieldDef[0].techName]"
          />
        </v-row>
        <v-row align="center" justify="center" class="ma-3">
          <BaseField
            :field="fieldDef[1]"
            v-model="data[fieldDef[1].techName]"
          />
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="3">
            <v-text-field
              v-model="data.createdBy"
              :error-messages="createdByErrors"
              label="Erstellt von"
              readonly
              required
              disabled
              @input="$v.data.createdBy.$touch()"
              @blur="$v.data.createdBy.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </form>
  </BaseForm>
</template>

<script>
import { API } from "aws-amplify";
import { updateOverwriteMonthInAboNull } from "@/graphql/mutations";
import {
  listArticles,
  listMarketingTypes,
  listConditions,
  getOverwriteMonthInAboNull,
} from "@/graphql/queries";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import BaseForm from "@/components/baseCrud/BaseForm.vue";
import BaseField from "@/components/baseCrud/BaseField.vue";

export default {
  name: "app",
  mixins: [validationMixin],
  components: {
    BaseForm,
    BaseField,
  },
  data() {
    return {
      data: {},
      minMaxList: [
        {
          id: 1,
          code: "MIN",
          name: "Untergrenze",
        },
        {
          id: 2,
          code: "MAX",
          name: "Obergrenze",
        },
      ],
      fieldDef: [
        {
          name: "Gültig von",
          techName: "validFrom",
          mandatory: true,
          fieldType: "date",
          default: new Date(),
        },
        {
          name: "Gültig bis",
          techName: "validTill",
          mandatory: true,
          fieldType: "date",
          default: new Date(),
        },
      ],
      articleList: [],
      marketingTypeList: [],
      contractConditionGroupList: [],
    };
  },
  validations: {
    data: {
      validFrom: { required },
      validTill: { required },
      articleID: { required },
      marketingTypeID: { required },
      conditionID: { required },
      minMax: { required },
      monthThreshold: { required },
      reason: { required },
      createdBy: { required },
    },
  },
  async created() {
    this.setCreatedBy();
    this.getArticleList();
    this.getMarketingTypeList();
    this.getContractConditionGroupList();
    this.getCurrentItemById(this.currentId);
    this.data.createdBy = this.kurzel;
  },
  methods: {
    async getCurrentItemById(id) {
      const response = await API.graphql({
        query: getOverwriteMonthInAboNull,
        variables: { id },
      });
      this.data = response.data.getOverwriteMonthInAboNull;
    },
    async createMethod() {
      const input = {
        id: this.data.id,
        articleID: this.data.articleID,
        marketingTypeID: this.data.marketingTypeID,
        conditionID: this.data.conditionID,
        monthThreshold: parseInt(this.data.monthThreshold, 10),
        validFrom: this.data.validFrom,
        validTill: this.data.validTill,
        createdBy: this.data.createdBy,
        minMax: this.data.minMax,
        reason: this.data.reason,
        _version: this.data._version,
      };
      await API.graphql({
        query: updateOverwriteMonthInAboNull,
        variables: { input: input },
      });
      this.$router.push({ name: "NgwOverwriteMonthInAboNullList" });
      this.$emit("refresh");
    },
    onConfirmClicked() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.createMethod();
    },
    async getArticleList() {
      this.loading = true;
      const response = await API.graphql({
        query: listArticles,
        variables: {
          limit: 10000,
        },
      });
      this.articleList = response.data.listArticles.items;
      this.loading = false;
    },
    async getMarketingTypeList() {
      this.loading = true;
      const response = await API.graphql({
        query: listMarketingTypes,
      });
      this.marketingTypeList = response.data.listMarketingTypes.items;
      this.loading = false;
    },
    async getContractConditionGroupList() {
      this.loading = true;
      const response = await API.graphql({
        query: listConditions,
      });
      this.contractConditionGroupList = response.data.listConditions.items;
      this.loading = false;
    },
    setCreatedBy() {
      if (this.user) {
        this.data.createdBy = this.user.email;
      }
    },
    onCancelClicked() {
      this.$router.go(-1);
    },
    articleDisplayText(item) {
      return `${item.mandatorCode} - ${item.articleCode}`;
    },
  },
  computed: {
    currentId() {
      return this.$route.params.id;
    },
    articleErrors() {
      const errors = [];
      if (!this.$v.data.articleID.$dirty) return errors;
      !this.$v.data.articleID.required && errors.push("Is required.");
      return errors;
    },
    marketingTypeErrors() {
      const errors = [];
      if (!this.$v.data.marketingTypeID.$dirty) return errors;
      !this.$v.data.marketingTypeID.required && errors.push("Is required.");
      return errors;
    },
    contractConditionGroupErrors() {
      const errors = [];
      if (!this.$v.data.conditionID.$dirty) return errors;
      !this.$v.data.conditionID.required && errors.push("Is required.");
      return errors;
    },
    minMaxErrors() {
      const errors = [];
      if (!this.$v.data.minMax.$dirty) return errors;
      !this.$v.data.minMax.required && errors.push("Is required.");
      return errors;
    },
    monthThresholdErrors() {
      const errors = [];
      if (!this.$v.data.monthThreshold.$dirty) return errors;
      !this.$v.data.monthThreshold.required && errors.push("Is required.");
      return errors;
    },
    reasonErrors() {
      const errors = [];
      if (!this.$v.data.reason.$dirty) return errors;
      !this.$v.data.reason.required && errors.push("Is required.");
      return errors;
    },
    validErrors() {
      const errors = [];
      if (!this.$v.data.valid.$dirty) return errors;
      !this.$v.data.valid.required && errors.push("Is required.");
      return errors;
    },
    createdByErrors() {
      const errors = [];
      if (!this.$v.data.createdBy.$dirty) return errors;
      !this.$v.data.createdBy.required && errors.push("Is required.");
      return errors;
    },
    ...mapGetters(["user", "kurzel"]),
  },
};
</script>