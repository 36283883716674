import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/landing/Main.vue'
import { Auth } from "aws-amplify";

import OrderCompensationOverviewList from '@/views/orderCompensation/overview/List.vue'
import OrderCompensationOverviewCreate from '@/views/orderCompensation/overview/CreateForm.vue'
import OrderCompensationOverviewUpdate from '@/views/orderCompensation/overview/UpdateForm.vue'


import PpcOneCpoList from '@/views/ppcOne/cpo/List.vue'
import PpcOneCpoCreateUpdateForm from '@/views/ppcOne/cpo/CreateUpdateForm.vue'

import PpcOneAccountList from '@/views/ppcOne/account/List.vue'
import PpcOneAccountCreateUpdateForm from '@/views/ppcOne/account/CreateUpdateForm.vue'

import PpcOneArticleList from '@/views/ppcOne/article/List.vue'
import PpcOneArticleCreateUpdateForm from '@/views/ppcOne/article/CreateUpdateForm.vue'

import NgwOverwriteArticlePriceList from '@/views/newBuisnessValue/overwriteArticlePrice/List.vue'
import NgwOverwriteArticlePriceCreate from '@/views/newBuisnessValue/overwriteArticlePrice/CreateForm.vue'
import NgwOverwriteArticlePriceUpdate from '@/views/newBuisnessValue/overwriteArticlePrice/UpdateForm.vue'

import NgwOverwriteContractPayedRatioList from '@/views/newBuisnessValue/overwriteContractPayedRatio/List.vue'
import NgwOverwriteContractPayedRatioCreate from '@/views/newBuisnessValue/overwriteContractPayedRatio/CreateForm.vue'
import NgwOverwriteContractPayedRatioUpdate from '@/views/newBuisnessValue/overwriteContractPayedRatio/UpdateForm.vue'

import NboSurveyLanding from '@/views/nbo/survey/Landing.vue'
import NboSurveyQuestion from '@/views/nbo/survey/Question.vue'
import NboSurveyFinal from '@/views/nbo/survey/Final.vue'

import NgwOverwriteMonthInAboNullList from '@/views/newBuisnessValue/overwriteMonthInAboNull/List.vue'
import NgwOverwriteMonthInAboNullCreate from '@/views/newBuisnessValue/overwriteMonthInAboNull/CreateForm.vue'
import NgwOverwriteMonthInAboNullUpdate from '@/views/newBuisnessValue/overwriteMonthInAboNull/UpdateForm.vue'

import NgwSimulator from '@/views/newBuisnessValue/simulator/Simulator.vue'

import MasterDataArticleList from '@/views/masterData/article/List.vue'
import MasterDataArticleCreate from '@/views/masterData/article/CreateForm.vue'
import MasterDataArticleUpdate from '@/views/masterData/article/UpdateForm.vue'

import MasterDataArticleTypeList from '@/views/masterData/articleType/List.vue'
import MasterDataArticleTypeCreate from '@/views/masterData/articleType/CreateForm.vue'
import MasterDataArticleTypeUpdate from '@/views/masterData/articleType/UpdateForm.vue'

import MasterDataConditionList from '@/views/masterData/condition/List.vue'
import MasterDataConditionCreate from '@/views/masterData/condition/CreateForm.vue'
import MasterDataConditionUpdate from '@/views/masterData/condition/UpdateForm.vue'

import MasterDataMarketingTypeList from '@/views/masterData/marketingType/List.vue'
import MasterDataMarketingTypeCreate from '@/views/masterData/marketingType/CreateForm.vue'
import MasterDataMarketingTypeUpdate from '@/views/masterData/marketingType/UpdateForm.vue'

import PriceSegmentPriceSegmentOverwriteList from '@/views/priceSegment/overwrite/List.vue'
import PriceSegmentPriceSegmentOverwriteCreate from '@/views/priceSegment/overwrite/CreateForm.vue'
import PriceSegmentPriceSegmentOverwriteUpdate from '@/views/priceSegment/overwrite/UpdateForm.vue'

import MasterDataMarketingTypeGroupList from '@/views/masterData/marketingTypeGroup/List.vue'
import MasterDataMarketingTypeGroupCreate from '@/views/masterData/marketingTypeGroup/CreateForm.vue'
import MasterDataMarketingTypeGroupUpdate from '@/views/masterData/marketingTypeGroup/UpdateForm.vue'

import Settings from '@/views/settings/Main.vue'

import TextAnalyse from '@/views/textAnalyse/mining/List.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { requiresAuth: true }
  },
  {
    path: '/orderCompensation/overview/list',
    name: 'OrderCompensationOverviewList',
    component: OrderCompensationOverviewList,
    meta: { requiresAuth: true }
  },
  {
    path: '/orderCompensation/overview/update/:id',
    name: 'OrderCompensationOverviewUpdate',
    component: OrderCompensationOverviewUpdate,
    meta: { requiresAuth: true }
  },
  {
    path: '/orderCompensation/overview/create',
    name: 'OrderCompensationOverviewCreate',
    component: OrderCompensationOverviewCreate,
    meta: { requiresAuth: true }
  },
  {
    path: '/text-analyse',
    name: 'TextAnalyse',
    component: TextAnalyse
  },
  {
    path: '/ngw/overwriteArticlePrice/list',
    name: 'NgwOverwriteArticlePriceList',
    component: NgwOverwriteArticlePriceList,
    meta: { requiresAuth: true }
  },
  {
    path: '/ngw/overwriteArticlePrice/create',
    name: 'NgwOverwriteArticlePriceCreate',
    component: NgwOverwriteArticlePriceCreate,
    meta: { requiresAuth: true }
  },
  {
    path: '/ngw/overwriteArticlePrice/update/:id',
    name: 'NgwOverwriteArticlePriceUpdate',
    component: NgwOverwriteArticlePriceUpdate,
    meta: { requiresAuth: true }
  },
  {
    path: '/ngw/overwriteContractPayedRatio/list',
    name: 'NgwOverwriteContractPayedRatioList',
    component: NgwOverwriteContractPayedRatioList,
    meta: { requiresAuth: true }
  },
  {
    path: '/ngw/overwriteContractPayedRatio/create',
    name: 'NgwOverwriteContractPayedRatioCreate',
    component: NgwOverwriteContractPayedRatioCreate,
    meta: { requiresAuth: true }
  },
  {
    path: '/ngw/overwriteContractPayedRatio/update/:id',
    name: 'NgwOverwriteContractPayedRatioUpdate',
    component: NgwOverwriteContractPayedRatioUpdate,
    meta: { requiresAuth: true }
  },
  {
    path: '/ngw/overwriteMonthInAboNull/list',
    name: 'NgwOverwriteMonthInAboNullList',
    component: NgwOverwriteMonthInAboNullList,
    meta: { requiresAuth: true }
  },
  {
    path: '/ngw/overwriteMonthInAboNull/create',
    name: 'NgwOverwriteMonthInAboNullCreate',
    component: NgwOverwriteMonthInAboNullCreate,
    meta: { requiresAuth: true }
  },
  {
    path: '/ngw/overwriteMonthInAboNull/update/:id',
    name: 'NgwOverwriteMonthInAboNullUpdate',
    component: NgwOverwriteMonthInAboNullUpdate,
    meta: { requiresAuth: true }
  },
  {
    path: '/ngw/simulator',
    name: 'NgwSimulator',
    component: NgwSimulator
  },
  {
    path: '/nbo/survey/landing',
    name: 'NboSurveyLanding',
    component: NboSurveyLanding,
    meta: { requiresAuth: true }
  },
  {
    path: '/nbo/survey/question',
    name: 'NboSurveyQuestion',
    component: NboSurveyQuestion,
    meta: { requiresAuth: true }
  },
  {
    path: '/nbo/survey/final',
    name: 'NboSurveyFinal',
    component: NboSurveyFinal,
    meta: { requiresAuth: true }
  },
  {
    path: '/ppc-one/cpo/list',
    name: 'PpcOneCpoList',
    component: PpcOneCpoList,
    meta: { requiresAuth: true }
  },
  {
    path: '/ppc-one/cpo/create-update-form',
    name: 'PpcOneCpoCreateUpdateForm',
    component: PpcOneCpoCreateUpdateForm,
    meta: { requiresAuth: true }
  },
  {
    path: '/ppc-one/account/list',
    name: 'PpcOneAccountList',
    component: PpcOneAccountList,
    meta: { requiresAuth: true }
  },
  {
    path: '/ppc-one/account/create-update-form',
    name: 'PpcOneAccountCreateUpdateForm',
    component: PpcOneAccountCreateUpdateForm,
    meta: { requiresAuth: true }
  },
  {
    path: '/ppc-one/article/list',
    name: 'PpcOneArticleList',
    component: PpcOneArticleList,
    meta: { requiresAuth: true }
  },
  {
    path: '/ppc-one/article/create-update-form',
    name: 'PpcOneArticleCreateUpdateForm',
    component: PpcOneArticleCreateUpdateForm,
    meta: { requiresAuth: true }
  },
  {
    path: '/price-segment/overwrite/list',
    name: 'PriceSegmentOverwriteList',
    component: PriceSegmentPriceSegmentOverwriteList,
    meta: { requiresAuth: true }
  },
  {
    path: '/price-segment/overwrite/create',
    name: 'PriceSegmentPriceSegmentOverwriteCreate',
    component: PriceSegmentPriceSegmentOverwriteCreate,
    meta: { requiresAuth: true }
  },
  {
    path: '/price-segment/overwrite/update/:id',
    name: 'PriceSegmentPriceSegmentOverwriteUpdate',
    component: PriceSegmentPriceSegmentOverwriteUpdate,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-data/article/list',
    name: 'MasterDataArticleList',
    component: MasterDataArticleList,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-data/article-type/create',
    name: 'MasterDataArticleTypeCreate',
    component: MasterDataArticleTypeCreate,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-data/article-type/update/:id',
    name: 'MasterDataArticleTypeUpdate',
    component: MasterDataArticleTypeUpdate,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-data/article-type/list',
    name: 'MasterDataArticleTypeList',
    component: MasterDataArticleTypeList,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-data/article/create',
    name: 'MasterDataArticleCreate',
    component: MasterDataArticleCreate,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-data/article/update/:id',
    name: 'MasterDataArticleUpdate',
    component: MasterDataArticleUpdate,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-data/condition/list',
    name: 'MasterDataConditionList',
    component: MasterDataConditionList,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-data/condition/create',
    name: 'MasterDataConditionCreate',
    component: MasterDataConditionCreate,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-data/condition/update/:id',
    name: 'MasterDataConditionUpdate',
    component: MasterDataConditionUpdate,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-data/marketing-type/list',
    name: 'MasterDataMarketingTypeList',
    component: MasterDataMarketingTypeList,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-data/marketing-type/create',
    name: 'MasterDataMarketingTypeCreate',
    component: MasterDataMarketingTypeCreate,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-data/marketing-type/update/:id',
    name: 'MasterDataMarketingTypeUpdate',
    component: MasterDataMarketingTypeUpdate,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-data/marketing-type-group/list',
    name: 'MasterDataMarketingTypeGroupList',
    component: MasterDataMarketingTypeGroupList,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-data/marketing-type/create',
    name: 'MasterDataMarketingTypeGroupCreate',
    component: MasterDataMarketingTypeGroupCreate,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-data/marketing-type-group/update/:id',
    name: 'MasterDataMarketingTypeGroupUpdate',
    component: MasterDataMarketingTypeGroupUpdate,
    meta: { requiresAuth: true }
  },
  {
    path: '/leadgen/agencyCost/list',
    name: 'leadgenAgencyCostList',
    component: () => import(/* webpackChunkName: "leadgenAgencyCostList" */ '../views/leadgen/agencyCost/List.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/leadgen/agencyCost/create',
    name: 'leadgenAgencyCostCreate',
    component: () => import(/* webpackChunkName: "leadgenAgencyCostCreate" */ '../views/leadgen/agencyCost/CreateForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/leadgen/agencyCost/duplicate/:id',
    name: 'leadgenAgencyCostDuplicate',
    component: () => import(/* webpackChunkName: "leadgenAgencyCostDuplicate" */ '../views/leadgen/agencyCost/DuplicateForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/leadgen/agencyCost/update/:id',
    name: 'leadgenAgencyCostUpdate',
    component: () => import(/* webpackChunkName: "leadgenAgencyCostUpdate" */ '../views/leadgen/agencyCost/UpdateForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/leadgen/cooperationCost/list',
    name: 'leadgenCooperationCostList',
    component: () => import(/* webpackChunkName: "leadgenCooperationCostList" */ '../views/leadgen/cooperationCost/List.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/leadgen/cooperationCost/create',
    name: 'leadgenCooperationCostCreate',
    component: () => import(/* webpackChunkName: "leadgenCooperationCostCreate" */ '../views/leadgen/cooperationCost/CreateForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/leadgen/cooperationCost/update',
    name: 'leadgenCooperationCostUpdate',
    component: () => import(/* webpackChunkName: "leadgenCooperationCostUpdate" */ '../views/leadgen/cooperationCost/UpdateForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/networkType/list',
    name: 'masterLeadgenNetworkTypeList',
    component: () => import(/* webpackChunkName: "masterLeadgenNetworkTypeList" */ '../views/masterLeadgen/networkType/List.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/networkType/create',
    name: 'masterLeadgenNetworkTypeCreate',
    component: () => import(/* webpackChunkName: "masterLeadgenNetworkTypeCreate" */ '../views/masterLeadgen/networkType/CreateForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/networkType/update',
    name: 'masterLeadgenNetworkTypeUpdate',
    component: () => import(/* webpackChunkName: "masterLeadgenNetworkTypeUpdate" */ '../views/masterLeadgen/networkType/UpdateForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/network/list',
    name: 'masterLeadgenNetworkList',
    component: () => import(/* webpackChunkName: "masterLeadgenNetworkList" */ '../views/masterLeadgen/network/List.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/network/create',
    name: 'masterLeadgenNetworkCreate',
    component: () => import(/* webpackChunkName: "masterLeadgenNetworkCreate" */ '../views/masterLeadgen/network/CreateForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/network/update',
    name: 'masterLeadgenNetworkUpdate',
    component: () => import(/* webpackChunkName: "masterLeadgenNetworkUpdate" */ '../views/masterLeadgen/network/UpdateForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/affiliateType/list',
    name: 'masterLeadgenAffiliateTypeList',
    component: () => import(/* webpackChunkName: "masterLeadgenAffiliateTypeList" */ '../views/masterLeadgen/affiliateType/List.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/affiliateType/create',
    name: 'masterLeadgenAffiliateTypeCreate',
    component: () => import(/* webpackChunkName: "masterLeadgenAffiliateTypeCreate" */ '../views/masterLeadgen/affiliateType/CreateForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/affiliateType/update',
    name: 'masterLeadgenAffiliateTypeUpdate',
    component: () => import(/* webpackChunkName: "masterLeadgenAffiliateTypeUpdate" */ '../views/masterLeadgen/affiliateType/UpdateForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/affiliateHardMapping/list',
    name: 'masterLeadgenAffiliateHardMappingList',
    component: () => import(/* webpackChunkName: "masterLeadgenAffiliateHardMappingList" */ '../views/masterLeadgen/affiliateHardMapping/List.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/affiliateHardMapping/create',
    name: 'masterLeadgenAffiliateHardMappingCreate',
    component: () => import(/* webpackChunkName: "masterLeadgenAffiliateHardMappingCreate" */ '../views/masterLeadgen/affiliateHardMapping/CreateForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/affiliateHardMapping/update',
    name: 'masterLeadgenAffiliateHardMappingUpdate',
    component: () => import(/* webpackChunkName: "masterLeadgenAffiliateHardMappingUpdate" */ '../views/masterLeadgen/affiliateHardMapping/UpdateForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/agency/list',
    name: 'masterLeadgenAgencyList',
    component: () => import(/* webpackChunkName: "masterLeadgenAgencyList" */ '../views/masterLeadgen/agency/List.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/agency/create',
    name: 'masterLeadgenAgencyCreate',
    component: () => import(/* webpackChunkName: "masterLeadgenAgencyCreate" */ '../views/masterLeadgen/agency/CreateForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/agency/update',
    name: 'masterLeadgenAgencyUpdate',
    component: () => import(/* webpackChunkName: "masterLeadgenAgencyUpdate" */ '../views/masterLeadgen/agency/UpdateForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/newsletter/list',
    name: 'masterLeadgenNewsletterList',
    component: () => import(/* webpackChunkName: "masterLeadgenNewsletterList" */ '../views/masterLeadgen/newsletter/List.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/newsletter/create',
    name: 'masterLeadgenNewsletterCreate',
    component: () => import(/* webpackChunkName: "masterLeadgenNewsletterCreate" */ '../views/masterLeadgen/newsletter/CreateForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/newsletter/update',
    name: 'masterLeadgenNewsletterUpdate',
    component: () => import(/* webpackChunkName: "masterLeadgenNewsletterUpdate" */ '../views/masterLeadgen/newsletter/UpdateForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/subpublisher/list',
    name: 'masterLeadgenSubpublisherList',
    component: () => import(/* webpackChunkName: "masterLeadgenSubpublisherList" */ '../views/masterLeadgen/subpublisher/List.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/subpublisher/create',
    name: 'masterLeadgenSubpublisherCreate',
    component: () => import(/* webpackChunkName: "masterLeadgenSubpublisherCreate" */ '../views/masterLeadgen/subpublisher/CreateForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/subpublisher/update',
    name: 'masterLeadgenSubpublisherUpdate',
    component: () => import(/* webpackChunkName: "masterLeadgenSubpublisherUpdate" */ '../views/masterLeadgen/subpublisher/UpdateForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/targetGroup/list',
    name: 'masterLeadgenTargetGroupList',
    component: () => import(/* webpackChunkName: "masterLeadgenTargetGroupList" */ '../views/masterLeadgen/targetGroup/List.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/targetGroup/create',
    name: 'masterLeadgenTargetGroupCreate',
    component: () => import(/* webpackChunkName: "masterLeadgenTargetGroupCreate" */ '../views/masterLeadgen/targetGroup/CreateForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/targetGroup/update',
    name: 'masterLeadgenTargetGroupUpdate',
    component: () => import(/* webpackChunkName: "masterLeadgenTargetGroupUpdate" */ '../views/masterLeadgen/targetGroup/UpdateForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/planningDivision/list',
    name: 'masterLeadgenPlanningDivisionList',
    component: () => import(/* webpackChunkName: "masterLeadgenPlanningDivisionList" */ '../views/masterLeadgen/planningDivision/List.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/planningDivision/create',
    name: 'masterLeadgenPlanningDivisionCreate',
    component: () => import(/* webpackChunkName: "masterLeadgenPlanningDivisionCreate" */ '../views/masterLeadgen/planningDivision/CreateForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/masterLeadgen/planningDivision/update',
    name: 'masterLeadgenPlanningDivisionUpdate',
    component: () => import(/* webpackChunkName: "masterLeadgenPlanningDivisionUpdate" */ '../views/masterLeadgen/planningDivision/UpdateForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reports/reports',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "masterLeadgenPlanningDivisionUpdate" */ '../views/reports/Reports.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/invoice/overwriteArticle',
    name: 'InvoiceOverwriteArticle',
    component: () => import(/* webpackChunkName: "masterLeadgenPlanningDivisionUpdate" */ '../views/invoice/overwriteArticle/List.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/invoice/overwriteArticle/create',
    name: 'InvoiceOverwriteArticleCreate',
    component: () => import(/* webpackChunkName: "masterLeadgenPlanningDivisionUpdate" */ '../views/invoice/overwriteArticle/CreateForm.vue'),
    props: true
  },
  {
    path: '/invoice/overwriteArticle/update/:id',
    name: 'InvoiceOverwriteArticleUpdate',
    component: () => import(/* webpackChunkName: "masterLeadgenPlanningDivisionUpdate" */ '../views/invoice/overwriteArticle/UpdateForm.vue'),
    props: true
  },
  {
    path: '/invoice/overwriteArticle/extend/:id',
    name: 'InvoiceOverwriteArticleExtend',
    component: () => import(/* webpackChunkName: "masterLeadgenPlanningDivisionUpdate" */ '../views/invoice/overwriteArticle/ExtendForm.vue'),
    props: true
  },
  {
    path: '/invoice/overwriteWKZ/list',
    name: 'InvoiceOverwriteWKZList',
    component: () => import(/* webpackChunkName: "masterLeadgenPlanningDivisionUpdate" */ '../views/invoice/overwriteWKZ/List.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/invoice/overwriteWKZ/create',
    name: 'InvoiceOverwriteWKZCreate',
    component: () => import(/* webpackChunkName: "masterLeadgenPlanningDivisionUpdate" */ '../views/invoice/overwriteWKZ/CreateForm.vue'),
    props: true
  },
  {
    path: '/invoice/overwriteWKZ/update/:id',
    name: 'InvoiceOverwriteWKZUpdate',
    component: () => import(/* webpackChunkName: "masterLeadgenPlanningDivisionUpdate" */ '../views/invoice/overwriteWKZ/UpdateForm.vue'),
    props: true
  },
  {
    path: '/invoice/overwriteWKZ/extend/:id',
    name: 'InvoiceOverwriteWKZExtend',
    component: () => import(/* webpackChunkName: "masterLeadgenPlanningDivisionUpdate" */ '../views/invoice/overwriteWKZ/ExtendForm.vue'),
    props: true
  },
  {
    path: '/ngw/overwriteNGW',
    name: 'InvoiceOverwriteNGWList',
    component: () => import(/* webpackChunkName: "masterLeadgenPlanningDivisionUpdate" */ '../views/newBuisnessValue/overwriteNGW/List.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/ngw/overwriteNGW/create',
    name: 'InvoiceOverwriteNGWCreate',
    component: () => import(/* webpackChunkName: "masterLeadgenPlanningDivisionUpdate" */ '../views/newBuisnessValue/overwriteNGW/CreateForm.vue'),
    props: true
  },
  {
    path: '/ngw/overwriteNGW/update/:id',
    name: 'InvoiceOverwriteNGWUpdate',
    component: () => import(/* webpackChunkName: "masterLeadgenPlanningDivisionUpdate" */ '../views/newBuisnessValue/overwriteNGW/UpdateForm.vue'),
    props: true
  },
  {
    path: '/ngw/overwriteNGW/extend/:id',
    name: 'InvoiceOverwriteNGWExtend',
    component: () => import(/* webpackChunkName: "masterLeadgenPlanningDivisionUpdate" */ '../views/newBuisnessValue/overwriteNGW/ExtendForm.vue'),
    props: true
  }
]

const router = new VueRouter({
  routes
})

router.beforeResolve((to, from, next) => {
  // console.log('debug TO: '+JSON.stringify(to));
  if (to.matched.some(record => record.meta.requiresAuth)) {
    Vue.prototype.$Amplify.Auth.currentAuthenticatedUser()
      .then(() => {
        next();
      })
      .catch((e) => {
        console.log(e);
        // let path = to.path;
        // if (to.query && to.query.id) {
        //   path = '?id='+to.path.id
        // }
        Auth.federatedSignIn({ provider: process.env.VUE_APP_COGNITO_PROVIDER, customState: to.fullPath });
      });
  } else {
    next();
  }

});

export default router
