<template>
  <BaseForm
    title="Vertrag Bezahlt Quote Überschreibung"
    @onConfirmClicked="onConfirmClicked"
  >
    <form>
      <v-container fluid>
        <v-row align="center" justify="center">
          <v-col cols="3">
            <v-autocomplete
              v-model="item.articleID"
              label="Artikel"
              item-value="id"
              item-text="code"
              :items="articleList"
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="item.marketingTypeID"
              label="Werbeweg"
              item-value="id"
              item-text="code"
              :items="marketingTypeList"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="item.conditionID"
              label="Vert. Kondition"
              item-value="id"
              item-text="name"
              :items="contractConditionGroupList"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="item.minMax"
              label="Grenztyp"
              :items="minMaxList"
              required
              item-value="code"
              item-text="name"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="item.contractPayedRatioTheshold"
              :error-messages="contractPayedRatioThesholdErrors"
              :counter="10"
              label="Quotengrenze in %"
              required
              @input="$v.item.contractPayedRatioTheshold.$touch()"
              @blur="$v.item.contractPayedRatioTheshold.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="6">
            <v-text-field
              v-model="item.reason"
              :error-messages="reasonErrors"
              label="Grund (Freitext)"
              required
              @input="$v.item.reason.$touch()"
              @blur="$v.item.reason.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" class="ma-3">
          <BaseField
            :field="fieldDef[0]"
            v-model="item[fieldDef[0].techName]"
          />
        </v-row>
        <v-row align="center" justify="center" class="ma-3">
          <BaseField
            :field="fieldDef[1]"
            v-model="item[fieldDef[1].techName]"
          />
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="3">
            <v-text-field
              v-model="createdBy"
              :error-messages="createdByErrors"
              label="Erstellt von"
              required
              disabled
              @input="$v.createdBy.$touch()"
              @blur="$v.createdBy.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </form>
  </BaseForm>
</template>

<script>
import { API } from "aws-amplify";
import { updateOverwriteContractPayedRatio } from "@/graphql/mutations";
import {
  listArticles,
  listMarketingTypes,
  listConditions,
  getOverwriteContractPayedRatio,
} from "@/graphql/queries";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import BaseForm from "@/components/baseCrud/BaseForm.vue";
import BaseField from "@/components/baseCrud/BaseField.vue";

export default {
  name: "app",
  mixins: [validationMixin],
  components: {
    BaseForm,
    BaseField,
  },
  data() {
    return {
      item: {},
      data: {},
      fieldDef: [
        {
          name: "Gültig von",
          techName: "validFrom",
          mandatory: true,
          fieldType: "date",
          default: new Date(),
        },
        {
          name: "Gültig bis",
          techName: "validTill",
          mandatory: true,
          fieldType: "date",
          default: new Date(),
        },
      ],
      minMaxList: [
        {
          id: 1,
          code: "MIN",
          name: "Untergrenze",
        },
        {
          id: 2,
          code: "MAX",
          name: "Obergrenze",
        },
      ],
      priceTypeList: [
        {
          id: 1,
          code: "FIX_PRICE",
          name: "Startpreis",
        },
        {
          id: 2,
          code: "MONTH_PRICE",
          name: "Monatspreis",
        },
      ],
      articleList: [],
      marketingTypeList: [],
      contractConditionGroupList: [],
    };
  },
  validations: {
    item: {
      validFrom: { required },
      validTill: { required },
      article: { required },
      minMax: { required },
      contractPayedRatioTheshold: { required },
      reason: { required },
      createdBy: { required },
    },
  },
  async created() {
    this.setCreatedBy();
    this.getContractConditionGroupList();
    this.getArticleList();
    this.getMarketingTypeList();
    this.getCurrentItemById(this.currentId);
    this.createdBy = this.kurzel;
  },
  methods: {
    async getCurrentItemById(id) {
      const response = await API.graphql({
        query: getOverwriteContractPayedRatio,
        variables: { id },
      });
      this.item = response.data.getOverwriteContractPayedRatio;
    },
    async updateMethod() {
      const input = {
        id: this.item.id,
        articleID: this.item.articleID,
        marketingTypeID: this.item.marketingTypeID,
        conditionID: this.item.conditionID,
        contractPayedRatioTheshold: this.item.contractPayedRatioTheshold,
        validFrom: this.item.validFrom,
        validTill: this.item.validTill,
        minMax: this.item.minMax,
        reason: this.item.reason,
        _version: this.item._version,
      };

      await API.graphql({
        query: updateOverwriteContractPayedRatio,
        variables: { input: input },
      });
      this.$router.push({ name: "NgwOverwriteContractPayedRatioList" });
      this.$emit("refresh");
    },
    onConfirmClicked() {
      this.updateMethod();
    },
    async getArticleList() {
      this.loading = true;
      const response = await API.graphql({
        query: listArticles,
        variables: {
          limit: 10000,
        },
      });
      this.articleList = response.data.listArticles.items.sort((a, b) => {
        if (a.code && b.code) {
          var textA = a.code.toUpperCase();
          var textB = b.code.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        }
        return 0;
      });
      this.loading = false;
    },
    async getMarketingTypeList() {
      this.loading = true;
      const response = await API.graphql({
        query: listMarketingTypes,
      });
      this.marketingTypeList = response.data.listMarketingTypes.items;
      this.loading = false;
    },
    async getContractConditionGroupList() {
      this.loading = true;
      const response = await API.graphql({
        query: listConditions,
      });
      this.contractConditionGroupList = response.data.listConditions.items;
      this.loading = false;
    },
    setCreatedBy() {
      if (this.user) {
        this.createdBy = this.user.email;
      }
    },
    onCancelClicked() {
      this.$router.go(-1);
    },
    articleDisplayText(item) {
      return `${item.mandatorCode} - ${item.articleCode}`;
    },
  },
  computed: {
    currentId() {
      return this.$route.params.id;
    },
    articleErrors() {
      const errors = [];
      if (!this.$v.item.article.$dirty) return errors;
      !this.$v.item.article.required && errors.push("Is required.");
      return errors;
    },
    minMaxErrors() {
      const errors = [];
      if (!this.$v.item.minMax.$dirty) return errors;
      !this.$v.item.minMax.required && errors.push("Is required.");
      return errors;
    },
    contractPayedRatioThesholdErrors() {
      const errors = [];
      if (!this.$v.item.contractPayedRatioTheshold.$dirty) return errors;
      !this.$v.item.contractPayedRatioTheshold.required &&
        errors.push("Is required.");
      return errors;
    },
    reasonErrors() {
      const errors = [];
      if (!this.$v.item.reason.$dirty) return errors;
      !this.$v.item.reason.required && errors.push("Is required.");
      return errors;
    },
    validErrors() {
      const errors = [];
      if (!this.$v.item.valid.$dirty) return errors;
      !this.$v.item.valid.required && errors.push("Is required.");
      return errors;
    },
    createdByErrors() {
      const errors = [];
      if (!this.$v.item.createdBy.$dirty) return errors;
      !this.$v.item.createdBy.required && errors.push("Is required.");
      return errors;
    },
    isApprovedErrors() {
      const errors = [];
      if (!this.$v.item.isApproved.$dirty) return errors;
      !this.$v.item.isApproved.required && errors.push("Is required.");
      return errors;
    },
    approvedByErrors() {
      const errors = [];
      if (!this.$v.item.approvedBy.$dirty) return errors;
      !this.$v.item.approvedBy.required && errors.push("Is required.");
      return errors;
    },
    ...mapGetters(["user", "kurzel"]),
  },
};
</script>