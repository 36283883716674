<template>
  <div>
    <div style="text-align: center">
      <h2>{{ this.title }}</h2>
      <div style="width: 1200px; margin: auto">{{ description }}</div>
      <iframe ref="iframeRef" id="iframeRef"
        :src="currentLink"
        style="border: none;overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:5px;left:5px;right:5px;bottom:5px" height="100%" width="100%"
      ></iframe>
      <!-- <div v-if="use_cases" style="width: 50%; margin: auto; text-align: left">
          <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Case
                </th>
                <th class="text-left">
                  Question
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in use_cases" :key="item.case">
                <td>{{ item.case }}</td>
                <td>{{ item.question }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        </div> -->
    </div>
    <!-- for debug -->
    <!-- <div>{{ embbedinglink }}</div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      test: '',
    };
  },
  props: {
    title: {
      type: String,
    },
    embbedinglink: {
      type: String,
    },
    description: {
      type: String,
    },
    use_cases: {
      type: Array,
    },
  },
  watch: {
    'iframeChecker'() {
      console.log('iframe1: '+this.$refs.iframeRef.getAttribute('src'));
      console.log('iframe2: '+document.getElementById("iframeRef").contentWindow.location.href);
      console.log('iframe3: '+document.getElementById("iframeRef").contentDocument.referrer)
    },
    '$route.query'() {
     console.log('router test');
     console.log('iframe4: '+this.$refs.iframeRef.getAttribute('src'));
    },
  },
  computed: {
  //   titleCase(string) {
  //     return string[0].toUpperCase() + string.slice(1).toLowerCase();
  //   },
  //   currentTitle() {
  //     console.log('title: ' + this.$route.query.title);
  //     return this.$route.query.title;
  //   },
    currentLink() {
      console.log('link: ' + this.$route.query.dashboard);
      return 'https://eu-central-1.quicksight.aws.amazon.com/sn/embed/share/accounts/812419731335/dashboards/'+this.$route.query.dashboard;
    },
    iframeChecker(){
      let iframe = document.getElementById('iframeRef');
      return iframe.contentDocument || iframe.contentWindow.document;
    }
  },
};
</script>
