<template>
  <BaseForm
    title="Neuen Account bearbeiten"
    @onConfirmClicked="onConfirmClicked"
  >
    <form>
      <v-text-field
        v-model="networkCode"
        :counter="10"
        label="networkCode"
        required
      ></v-text-field>
      <v-text-field
        v-model="accountId"
        :counter="10"
        label="accountId"
        required
      ></v-text-field>
      <v-text-field
        v-model="description"
        label="description"
        required
      ></v-text-field>
    </form>
  <v-container fluid>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="createdBy"
            label="Erstellt von"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </BaseForm>
</template>

<script>
import { API } from "aws-amplify";
import { createOneStepAccounts } from "@/graphql/mutations";
import BaseForm from "@/components/baseCrud/BaseForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "app",

  components: {
    BaseForm,
  },
  data() {
    return {
      networkCode: "dff",
      accountId: "dfd",
      internExtern: "INTERN",
      description: "fds",
      createdBy: this.createdBy,
      internExternList: ["INTERN", "EXTERN"],
      articleItems: [],
    };
  },
  async created() {
    this.createdBy = this.kurzel;
  },
  methods: {
    async createMethod() {
      await API.graphql({
        query: createOneStepAccounts,
        variables: {
          input: {
            networkCode: this.networkCode,
            accountId: this.accountId,
            description: this.description,
            createdBy: this.createdBy,
          },
        },
      });
      this.$router.push({ name: "PpcOneAccountList" });
      this.$emit("refresh");
    },
    onConfirmClicked() {
      this.createMethod();
    },
  },
  computed: {
    ...mapGetters(["user", "kurzel"]),
  },
};
</script>