<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-progress-linear
        :value="getCount"
        height="25"
        readonly
        striped
        color="green"
      >
        <strong>{{ `Zu ${Math.ceil(getCount)}% fertig` }}</strong>
      </v-progress-linear>
    </v-row>
    <v-row v-if="!isFinished">
      <v-col cols="4">
        <v-card>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="success" dark v-bind="attrs" v-on="on">
                mdi-help-circle-outline
              </v-icon>
            </template>
            {{ t('trainTableTooltip') }}
          </v-tooltip>
          <v-card-title>
            {{ t('trainTable')}}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Anfo-Datum</th>
                  <th class="text-left">Bereich</th>
                  <th class="text-left">Artikel</th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Werbeweg</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in convertSimpleJson(rec.purchase_train)"
                  :key="item.id"
                >
                  <td>{{ item.contract_created_date }}</td>
                  <td>{{ item.business_part_code }}</td>
                  <td>{{ item.article_code }}</td>
                  <td>{{ item.contract_status_cd }}</td>
                  <td>{{ item.marketing_type_cd }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="success" dark v-bind="attrs" v-on="on">
                mdi-help-circle-outline
              </v-icon>
            </template>
            {{ t('recsTableTooltip') }}
          </v-tooltip>
          <v-card-title>
            {{ t('nboRecs')}}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Rank</th>
                  <th class="text-left">Artikel</th>
                  <th class="text-left">Bereich</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, i) in convertJson(rec.recommendations)"
                  :key="item.id"
                >
                  <td>{{ i + 1 }}</td>
                  <td>{{ item.article_code }}</td>
                  <td>{{ item.business_part_code }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="success" dark v-bind="attrs" v-on="on">
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
                  {{ t('testTableTooltip') }}
                </span>
          </v-tooltip>
          <v-card-title>
            {{ t('testTable')}}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Anfo-Datum</th>
                  <th class="text-left">Bereich</th>
                  <th class="text-left">Artikel</th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Werbeweg</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in convertSimpleJson(rec.purchase_test)"
                  :key="item.id"
                >
                  <td>{{ item.contract_created_date }}</td>
                  <td>{{ item.business_part_code }}</td>
                  <td>{{ item.article_code }}</td>
                  <td>{{ item.contract_status_cd }}</td>
                  <td>{{ item.marketing_type_cd }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else align="center" justify="center">
      <v-container>
        <v-row align="center" justify="center">
          <router-link :to="{ name: 'NboSurveyFinal' }" class="no-underline">
            <v-btn class="ma-2" color="primary">
              <v-icon left>mdi-fast-forward</v-icon>
              Weiter
            </v-btn>
          </router-link>
        </v-row>
      </v-container>
    </v-row>

    <v-bottom-navigation grow fixed v-model="value">
      <v-tooltip top open-delay="1000">
        <template v-slot:activator="{ on, attrs }">
          <v-btn large @click="onClickFeedback(0)" v-bind="attrs" v-on="on">
            <span>{{ t('notFit')}}</span>
            <v-icon large color="red"> mdi-emoticon-confused </v-icon>
          </v-btn>
        </template>
        <span>Die Vorhersage passt nicht zu den Käufen.</span>
      </v-tooltip>
      <v-tooltip top open-delay="1000">
        <template v-slot:activator="{ on, attrs }">
          <v-btn large @click="onClickFeedback(1)" v-bind="attrs" v-on="on">
            <span>{{ t('fit')}}</span>
            <v-icon large color="success"> mdi-emoticon-happy </v-icon>
          </v-btn>
        </template>
        <span>Die Vorhersage passt zu den Käufen.</span>
      </v-tooltip>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
import { API } from "aws-amplify";

import { getNboRecBacktestResult } from "@/graphql/queries";
import { createNboSurveyAnswers } from "@/graphql/mutations";
// import { listNboRecBacktestIds } from "@/graphql/customQueries";
// import HeimabendCard from '@/views/heimabend/cards/Heimabend.vue'; // eslint-disable-line
import { mapGetters } from "vuex";
import { commonMixin } from '@/mixins/commonMixin';

export default {
  mixins: [commonMixin],
  props: [],

  components: {},

  data: () => ({
    items: [],
    loading: true,
    show: true,
    trans: "slide-right",
    count: 0,
    value: 0,
    score: 0,
    decision: 1,
    isLoading: true,
    rec: {},
  }),

  created() {
    this.getCurrentRec();
    this.setTranslations({
      'de': {
          'languageButtonText': "Sprache bestätigen",
          'notFit': "Passt nicht",
          'fit': "Passt",
          'trainTable': "Käufe Vor Empfehlungen (Trainingsdaten für NBO)",
          'nboRecs': "Empfehlungen des NBOs",
          'testTable': "Käufe nach Empfehlungen (Backtest)",
          'trainTableTooltip': "Die vom angegebenen Kunden gekauften Artikel, die für die " +
            "Ausbildung von NBO verwendet wurden",
          'recsTableTooltip': "Top-Ten-Artikel, die von NBO basierend auf den Einkäufen des" +
            " Kunden empfohlen werden",
          'testTableTooltip': 'Die tatsächlich von den Kunden gekauften Artikel, die zum ' +
            'Testen der NBO-Leistung verwendet wurden'
      },
      'en': {
          'languageButtonText': "Use language",
          'notFit': "Does not Fit",
          'fit': "Fits",
          'trainTable': "Past purchases of the customer: ",
          'nboRecs': "Recommendations from the NBO:",
          'testTable': "Purchases after the recommendations:",
          'trainTableTooltip': "The articles purchased by the given customer which were used " +
            "for NBO training.",
          'recsTableTooltip': "Top ten articles recommended by NBO based on the purchases" +
            " of the customer",
          'testTableTooltip': "List of articles which were actually bought by the customers " +
            "and were used for testing NBO performance"
      }
  })
  },
  computed: {
    ...mapGetters(["user", "kurzel", "nboList"]),

    getCount() {
      return Math.min(this.count * 20, 100);
    },
    isFinished() {
      return this.getCount >= 99;
    },
  },
  methods: {
    getCurrentRec() {
      this.getNboElementId().then((response) => {
        if (response) {
          this.getCurrentItemById(response.id);
        } else {
          this.$router.push({
            name: "NboSurveyLanding",
          });
        }
      });
    },
    convertJson(string) {
      if (!string) {
        return {};
      }
      string = string.split(":");
      string.shift();
      string = string.join(":");
      string = string.split("]");
      string.pop();
      string = string.join("]");
      return JSON.parse(`${string}]`);
    },
    convertSimpleJson(string) {
      if (!string) {
        return [];
      }
      return JSON.parse(string);
    },
    resetCount() {
      // this.getFirstEvent();
      this.count = 0;
    },
    async getNboElementId() {
      return this.nboList[this.count];
    },
    async getCurrentItemById(id) {
      const response = await API.graphql({
        query: getNboRecBacktestResult,
        variables: { id },
      });
      this.rec = response.data.getNboRecBacktestResult;
    },

    async saveDecision(anwser) {
      await API.graphql({
        query: createNboSurveyAnswers,
        variables: {
          input: {
            result: anwser,
            nboRecBacktestResultID: this.rec.id,
            createdBy: this.kurzel,
          },
        },
      });
    },

    onClickFeedback(anwser) {
      this.saveDecision(anwser);
      window.scrollTo(0, 0);
      this.count = this.count + 1;
      this.checkLast();
      this.getCurrentRec();
    },
    checkLast() {
      if (this.isFinished) {
        this.$router.push({
          name: "NboSurveyFinal",
        });
      }
    },
  },
};
</script>

<style scoped>
.slide-right-leave-active {
  transition: all 0.7s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-right-leave-to {
  transform: translateX(500px);
}
.slide-left-leave-active {
  transition: all 0.7s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-left-leave-to {
  transform: translateX(-500px);
}
.slide-up-leave-active {
  transition: all 0.7s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-up-leave-to {
  transform: translateY(-500px);
}
.slide-down-leave-active {
  transition: all 0.7s ease-in-out;
}
.slide-down-leave-to {
  transform: scale(0.3);
}
span {
  align-items: center;
}
.vertical-center {
  display: inline-flex;
  align-items: center;
}
</style>
