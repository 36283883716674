<template>
  <BaseForm
    title="Neue Preis Überschreibung"
    @onConfirmClicked="onConfirmClicked"
  >
    <form>
      <v-container>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-select
              v-model="data.articleID"
              label="Artikel"
              item-value="id"
              item-text="code"
              :items="articleList"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="data.minMax"
              label="Grenztyp"
              :items="minMaxList"
              required
              item-value="code"
              item-text="name"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="data.type"
              label="Preistyp"
              :items="priceTypeList"
              required
              item-value="code"
              item-text="name"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="data.priceThreshold"
              :error-messages="priceThresholdErrors"
              :counter="10"
              label="Preisgrenze"
              required
              suffix="€"
              @input="$v.data.priceThreshold.$touch()"
              @blur="$v.data.priceThreshold.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-text-field
              v-model="data.reason"
              :error-messages="reasonErrors"
              label="Grund (Freitext)"
              required
              @input="$v.data.reason.$touch()"
              @blur="$v.data.reason.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" class="ma-3">
          <BaseField
            :field="fieldDef[0]"
            v-model="data[fieldDef[0].techName]"
          />
        </v-row>
        <v-row align="center" justify="center" class="ma-3">
          <BaseField
            :field="fieldDef[1]"
            v-model="data[fieldDef[1].techName]"
          />
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-text-field
              v-model="data.createdBy"
              :error-messages="createdByErrors"
              label="Erstellt von"
              readonly
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </form>
  </BaseForm>
</template>

<script>
import { API } from "aws-amplify";
import { createOverwriteArticlePrice } from "@/graphql/mutations";
import { listArticles } from "@/graphql/queries";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

import BaseForm from "@/components/baseCrud/BaseForm.vue";
import BaseField from "@/components/baseCrud/BaseField.vue";

export default {
  name: "app",
  mixins: [validationMixin],
  components: {
    BaseForm,
    BaseField,
  },
  data() {
    return {
      data: {
        articleID: null,
        minMax: "MIN",
        priceThreshold: null,
        reason: "",
        type: "MONTH_PRICE",
        createdBy: "",
        validFrom: null,
        validTill: null,
      },
      fieldDef: [
        {
          name: "Gültig von",
          techName: "validFrom",
          mandatory: true,
          fieldType: "date",
          default: new Date(),
        },
        {
          name: "Gültig bis",
          techName: "validTill",
          mandatory: true,
          fieldType: "date",
          default: new Date(),
        },
      ],
      minMaxList: [
        {
          id: 1,
          code: "MIN",
          name: "Untergrenze",
        },
        {
          id: 2,
          code: "MAX",
          name: "Obergrenze",
        },
      ],
      priceTypeList: [
        {
          id: 1,
          code: "FIX_PRICE",
          name: "Startpreis",
        },
        {
          id: 2,
          code: "MONTH_PRICE",
          name: "Monatspreis",
        },
      ],
      articleList: [],
    };
  },
  validations: {
    data: {
      validFrom: { required },
      validTill: { required },
      articleID: { required },
      minMax: { required },
      priceThreshold: { required },
      reason: { required },
      createdBy: { required },
    },
  },
  async created() {
    this.setCreatedBy();
    this.getArticleList();
    this.data.createdBy = this.kurzel;
  },
  methods: {
    async createMethod() {
      const input = {
        articleID: this.data.articleID,
        priceThreshold: this.data.priceThreshold,
        validFrom:this.convertDate(this.data.validFrom),
        validTill:this.convertDate(this.data.validTill),
        createdBy: this.data.createdBy,
        minMax: this.data.minMax,
        reason: this.data.reason,
        type: this.data.type,
      };
      await API.graphql({
        query: createOverwriteArticlePrice,
        variables: { input: input },
      });
      this.$router.push({ name: "NgwOverwriteArticlePriceList" });
      this.$emit("refresh");
    },
    convertDate(date) {
      return this.$moment(date, 'YYYY-MM-DD','de').format('YYYY-MM-DD')
    },
    onConfirmClicked() {
      this.createMethod();
    },
    async getArticleList() {
      this.loading = true;
      const response = await API.graphql({
        query: listArticles,
        variables: {
          limit: 10000,
        },
      });
      this.articleList = response.data.listArticles.items;
      this.loading = false;
    },
    setCreatedBy() {
      if (this.user && this.user.attributes) {
        this.data.createdBy = this.user.attributes.email;
      }
    },
    onCancelClicked() {
      this.$router.go(-1);
    },
    articleDisplayText(item) {
      return `${item.mandatorCode} - ${item.articleCode}`;
    },
  },
  computed: {
    articleErrors() {
      const errors = [];
      if (!this.$v.data.articleID.$dirty) return errors;
      !this.$v.data.article.required && errors.push("Is required.");
      return errors;
    },
    minMaxErrors() {
      const errors = [];
      if (!this.$v.data.minMax.$dirty) return errors;
      !this.$v.data.minMax.required && errors.push("Is required.");
      return errors;
    },
    priceThresholdErrors() {
      const errors = [];
      if (!this.$v.data.priceThreshold.$dirty) return errors;
      !this.$v.data.priceThreshold.required && errors.push("Is required.");
      return errors;
    },
    reasonErrors() {
      const errors = [];
      if (!this.$v.data.reason.$dirty) return errors;
      !this.$v.data.reason.required && errors.push("Is required.");
      return errors;
    },
    validTillErrors() {
      const errors = [];
      if (!this.$v.data.validTill.$dirty) return errors;
      !this.$v.data.validTill.required && errors.push("Is required.");
      return errors;
    },
    validFromErrors() {
      const errors = [];
      if (!this.$v.data.validFrom.$dirty) return errors;
      !this.$v.data.validFrom.required && errors.push("Is required.");
      return errors;
    },
    createdByErrors() {
      const errors = [];
      if (!this.$v.data.createdBy.$dirty) return errors;
      !this.$v.data.createdBy.required && errors.push("Is required.");
      return errors;
    },
    ...mapGetters(["user", "kurzel"]),
  },
};
</script>