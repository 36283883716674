<template>
  <BaseForm
    title="Anfo Vergütung korrigieren"
    @onConfirmClicked="onConfirmClicked"
  >
    <form>
      <v-select
      v-model="mandatorCode"
      label="Mandant"
      :items="mandatorList"
      required
    ></v-select>
      <v-text-field
        v-model="articleCode"
        :counter="10"
        label="Artikel"
        required
      ></v-text-field>
      <v-switch
        v-model="isEmf"
        label="EMF?"
        required
      ></v-switch>
    </form>
    <v-text-field v-model="createdAt" label="createdAt" disabled></v-text-field>
    <v-text-field v-model="createdBy" label="createdBy" disabled></v-text-field>
  </BaseForm>
</template>

<script>
import { API } from "aws-amplify";
import { createOneStepArticleMapping } from "@/graphql/mutations";
import BaseForm from "@/components/baseCrud/BaseForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "app",

  components: {
    BaseForm,
  },
  data() {
    return {
      mandatorCode: 'VNR',
      articleCode: null,
      isEmf: false,
      internExtern: "INTERN",
      mandatorList: ["VNR", "FKD"],
      menu: false,
      createdBy: [],
    };
  },
  async created() {
    this.createdBy = this.kurzel;
  },
  methods: {
    async createMethod() {
      const {
        mandatorCode,
        articleCode,
        isEmf,
        internExtern,
        createdBy
      } = this;
      const input = {
        mandatorCode,
        articleCode,
        isEmf,
        internExtern,
        createdBy,
      };
      await API.graphql({
        query: createOneStepArticleMapping,
        variables: { input: input },
      });
      this.$router.push({ name: "PpcOneArticleList" });
      this.$emit('refresh');
    },
    onConfirmClicked() {
      this.createMethod();
    },
  },
  computed: {
    ...mapGetters(["user", "kurzel"]),
  },
};
</script>