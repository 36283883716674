<template>
  <v-container fluid>
    <BaseTable
      :items="items"
      :headers="headers"
      title="Überschreibung: Vertrag Bezahlt"
      componentName="NgwOverwriteContractPayedRatio"
      :editMutation="'updateOverwriteContractPayedRatio'"
      :deleteMutation="'deleteOverwriteContractPayedRatio'"
      @refresh="updateList"
      :loading="loading"
      isApprovedButton
    />
  </v-container>
</template>

<script>
import { API } from "aws-amplify";
import { listOverwriteContractPayedRatios } from "@/graphql/queries";
import BaseTable from "@/components/BaseTable.vue";

export default {
  name: "Anfo",
  components: {
    BaseTable,
  },
  async created() {
    this.updateList();
  },
  methods: {
    updateList() {
      this.getList();
    },
    async getList() {
      this.loading = true;
      const response = await API.graphql({
        query: listOverwriteContractPayedRatios,
        variables: {
          limit: 1000
        }
      });
      this.items = response.data.listOverwriteContractPayedRatios.items;
      this.loading = false;
    },
  },
  data() {
    return {
      headers: [
        { text: "Artikel", value: "article.code" },
        { text: "Werbeweg", value: "marketingType.code" },
        {
          text: "Kondition",
          value: "condition.name",
        },
        { text: "Grenztyp", value: "minMax" },
        {
          text: "Quotengrenze in %",
          value: "contractPayedRatioTheshold",
        },
        { text: "Grund", value: "reason" },
        { text: "Gültig von", value: "validFrom" },
        { text: "Gültig bis", value: "validTill" },
        { text: "Freig. Verlag", value: "approvedPublisherBy" },
        { text: "Freig. Controll.", value: "approvedControllingBy" },
      ],
      items: [],
      loading: true,
    };
  },
};
</script>
