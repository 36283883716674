<template>
  <v-container fluid>
    <BaseTable
      :items="items"
      :headers="headers"
      title="Accounts"
      :editLink="'PpcOneAccountCreateUpdateForm'"
      :createLink="'PpcOneAccountCreateUpdateForm'"
      :deleteMutation="'deleteOneStepAccounts'"
      @refresh="updateList"
      :loading="loading"
    />
  </v-container>
</template>

<script>
import { API } from "aws-amplify";
import { listOneStepAccounts } from "@/graphql/queries";
import BaseTable from "@/components/BaseTable.vue";

export default {
  name: "Anfo",
  components: {
    BaseTable,
  },
  async created() {
    this.updateList();
  },
  methods: {
    updateList() {
      this.getList();
    },
    async getList() {
      this.loading = true;
      const response = await API.graphql({ query: listOneStepAccounts });
      this.items = response.data.listOneStepAccounts.items;
      this.loading = false;
    },
  },
  data() {
    return {
      headers: [
        { text: "Network-Code", value: "networkCode" },
        { text: "Account-Id", value: "accountId" },
      ],
      items: [],
      loading: true,
    };
  },
};
</script>
