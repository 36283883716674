<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="9" class="ma-2">
        <v-textarea
          v-model="text"
          filled
          tile
          clearable
          auto-grow
          prepend-icon="mdi-comment"
          :disabled="!!isLoading || !!result"
          label="Gib hier deinen Text ein."
        ></v-textarea>
      </v-col>
      <v-col cols="12" sm="2" class="ma-2" v-if="!result">
        <v-select
          disabled
          v-model="language"
          :items="languageArray"
          label="Sprache"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3" class="ma-2">
        <v-btn
          :disabled="!!isLoading"
          color="success"
          v-if="!result"
          tile
          @click="onSendButtonClicked"
        >
          <v-icon left> mdi-calculator </v-icon>
          Text bewerten
        </v-btn>
        <v-btn color="error" v-if="result" tile @click="onRestoreButtonClicked">
          <v-icon left> mdi-undo </v-icon>
          Neuer Text
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" v-if="isLoading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="blue"
        indeterminate
      ></v-progress-circular>
    </v-row>
    <v-row class="my-2" align="center" justify="center" v-if="!!isLoading">
      <p class="font-weight-light">Habe bitte einen Moment Geduld...</p>
    </v-row>
    <v-row justify="center" v-if="result && !isLoading">
      <v-col cols="12" md="9" class="ma-2">
        <v-textarea
          readonly
          outlined
          prepend-icon="mdi-robot"
          label="Automatisch generierte Zusammenfassung"
          v-model="summary.name_de"
        ></v-textarea>
      </v-col>
      <v-col
        cols="12"
        sm="3"
        v-for="score in scores"
        :key="score.englishString"
      >
        <v-simple-table>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title> {{ score.germanString }}</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Wert</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in score.scores" :key="item.id">
                <td>{{ item.name_de }}</td>
                <td>{{ item.score }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { API } from "aws-amplify";

export default {
  data: () => ({
    languageArray: ["de", "en"],
    language: "de",
    text: "",
    result: null,
    isLoading: false,
  }),
  methods: {
    onRestoreButtonClicked() {
      this.result = null;
    },
    onSendButtonClicked() {
      this.isLoading = true;

      const apiName = "textMining";
      const path = "/textMining";
      const myInit = {
        queryStringParameters: {
          text: this.text,
          language: this.language,
        },
      };

      API.get(apiName, path, myInit)
        .then((response) => {
          this.result = response;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
  },
  computed: {
    scores() {
      if (this.result.result) {
        const { Summary, ...other } = this.result.result; // eslint-disable-line
        return other;
      }
      return [];
    },
    summary() {
      let returnValue = [];
      if (this.result.result) {
        returnValue = this.result.result.Summary;
      }
      return returnValue;
    },
  },
};
</script>