<template>
  <v-container class="default-max-width">
    <v-row align="center" justify="center">
      <h2>{{ title }}</h2>
    </v-row>
    <slot></slot>
    <v-row>
      <v-divider class="my-4" />
    </v-row>
    <v-row justify="end">
      <v-btn class="mx-2" @click="onCancelClicked" tile>
        <v-icon left>
          mdi-cancel
          </v-icon>
        Abbrechen </v-btn>
      <v-btn class="mx-2" @click="onConfirmClicked" tile color="success">
        <v-icon left>
          mdi-content-save
          </v-icon>
        Speichern
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Formular'
    },
  },
  methods: {
    onConfirmClicked() {
      this.$emit("onConfirmClicked");
    },
    onCancelClicked() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.default-max-width {
  max-width: 800px !important;
}
</style>
