<template>
  <v-container>
    <BaseTable
      :items="items"
      :headers="headers"
      title="Anfo Vergütung"
      :componentName="'OrderCompensationOverview'"
      :deleteMutation="'deleteOrderCompensation'"
      @refresh="updateList"
      :loading="loading"
    />
  </v-container>
</template>

<script>
import { API } from "aws-amplify";
import { listOrderCompensations } from "@/graphql/queries";
import BaseTable from '@/components/BaseTable.vue';


export default {
  name: "Anfo",
  components: {
    BaseTable,
  },
  async created() {
    this.updateList();
  },
  methods: {
    updateList() {
      this.getList();
    },
    async getList() {
      this.loading = true;
      const response = await API.graphql({
        query: listOrderCompensations,
      });
      this.items = response.data.listOrderCompensations.items;
      this.loading = false;
    },
  },
  data() {
    return {
      loading: true,
      headers: [
        {
          text: "Artikel",
          align: "start",
          sortable: true,
          value: "article",
        },
        { text: "Werbeweg", value: "marketingType.code" },
        { text: "Vergütung", value: "price" },
        { text: "Gültig Ab", value: "validFrom" },
        { text: "Beschreibung", value: "description" },
      ],
      items: [],
    };
  },
};
</script>
