<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col md="6" class="text-justify" justify="center">
        <h1 class="display-1 ma-3"> {{ t('welcomeToNbo') }}</h1>
        <br/>
        <p  class="font-weight-medium">
          <span class="font-italic">
            {{ t('nboIntro')}} <br />
          </span>
          <br />
            {{ t('taskDesc')}}
        </p>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" v-if="!loading">
      <v-col align="center" justify="center" cols="12">
        <router-link :to="{ name: 'NboSurveyQuestion' }" class="no-underline">
          <v-btn class="ma-2" color="success">
            {{ t('iamReady') }}
            <v-icon right>mdi-chevron-right</v-icon>
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row v-else class="mt-10" align="center" justify="center">
      <v-progress-circular
        :size="80"
        :width="10"
        class="ma-5"
        indeterminate
      ></v-progress-circular>
      <span class="subtitle-1 whiteText"> {{ t('pleaseWait')}} </span>
    </v-row>
  </v-container>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";

import { listNboRecBacktestIds } from "@/graphql/customQueries";
import { commonMixin } from '@/mixins/commonMixin';

export default {
  mixins: [commonMixin],
  data: () => ({
    loading: true,
    show: true,
    items: [],
  }),
  created() {
    this.loading = true;
    this.getAllIds().then((response) => {
      const items = response.data.listNboRecBacktestResults.items;
      // Shuffle array
      const shuffled = items.sort(() => 0.5 - Math.random());

      // Get sub-array of first n elements after shuffled
      const listItems = shuffled.slice(0, 10);
      this.$store.commit("setNboList", listItems);
      this.loading = false;
    });
    this.setTranslations({
      'de': {
        'pleaseWait': "Bitte hab etwas Geduld...",
        'iamReady': 'Ich bin bereit',
        'welcomeToNbo': 'Herzlich Willkommen bei der NBO Umfrage.',
        'nboIntro': 'Next-Best-Offer (NBO) ist ein Empfehlungssystem, ' +
          'mit dem den Kunden der Artikel in der Marketingkampagne empfohlen werden kann. ' +
          'Die NBO nutzt die vergangenen Käufe der Kunden, um zu lernen und empfiehlt dann ' +
          'die Artikel basierend auf ihren Käufen. Zur Messung der Präzision von NBO wird ein' +
          ' Backtesting verwendet, bei dem der NBO aus den Käufen ab einem bestimmten Zeitraum ' +
          '(Beispiel: von 2015 bis 2020) lernt und die Käufe nach dem Zeitrahmen der' +
          ' Lerndaten (Beispiel: 2021) für die Auswertungen. Die Auswertung erfolgt, ' +
          'indem geprüft wird, ob die empfohlenen Artikel tatsächlich vom Kunden gekauft ' +
          'wurden oder nicht.',
        'taskDesc': 'Wir bitten dich den NBO-Score zu bewerten. Dazu bekommst du 5 '
          + 'Empfehlungen und du sollst bei jeder Empfehlungen sagen ob sie "Passt"'
          + 'oder "Nicht Passt".'
      },
      'en': {
        'pleaseWait': "Please Wait...",
        'iamReady': 'I am ready to start',
        'welcomeToNbo': 'Welcome to the NBO elevation',
        'nboIntro': 'Next-Best-Offer (NBO) is a system which recommends articles to customers. ' +
          'The NBO uses the past purchases of the customers to learn and then recommends new ' +
          'articles. A backtesting is used to measure the precision of NBO. For historical data,' +
          ' a recommendation is simulated for a certain point of time. ' +
          'This recommendation is than compared to the actual purchases. ' +
          'If the recommendation and the actual purchase “fit”, the backtest confirms the models' +
          ' accuracy.',
        'taskDesc': 'We ask for your help to evaluate the NBO score. You will receive 5 different ' +
          'results of the NBO. The past purchases used for the training, the NBO recommendations' +
          ' based on the past purchases and the articles that were actually bought after the ' +
          'recommendations are shown in three different tables. You should determine whether the' +
          ' recommendations makes sense or not. If you think the recommendations makes sense, ' +
          'please click "Fits" otherwise "Does not Fit"'

      }
    })
  },
  methods: {
    async getAllIds() {
      this.loading = true;
      return await API.graphql(
        graphqlOperation(listNboRecBacktestIds, {
          limit: 1000,
          filter: {
            bp_id: {
              beginsWith: String(Math.floor(Math.random() * 9) + 1, 10),
            },
          },
        })
      );
    },
  },
};
</script>
